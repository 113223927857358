import API from './apiClient'
import { replace, goBack } from 'connected-react-router';
import { call, put, throttle, takeEvery, select } from 'redux-saga/effects'
import * as A from '../actions/noticeAction'
import { SiteUrls } from '../routes/siteUrl'
import { simpleAPI, simpleInfoAPI } from './common'
import { ENQUEUE_NOTIFICATION, NOTI_TYPE } from '../actions/notificationAction'
import { selectNoticeList } from '../selectors/noticeSelector'

function* getList({ payload }) {
  yield call(
    simpleAPI,
    () => API().noticeList(payload.page, payload.pageSize, payload.sort, payload.filter),
    A.getNoticeList,
  )
}

function* post({ payload }) {
  const result = yield call(
    simpleAPI,
    () => API().postNotice(payload),
    A.postNotice,
  )
  if (result) {
    yield put(replace(SiteUrls.Dashboard.Notice.List))
    yield put(ENQUEUE_NOTIFICATION('새로운 공지가 생성되었습니다.', NOTI_TYPE.SUCCESS))
  }
}

function* getInfo({ payload: id }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().getNotice(id),
    A.getNotice
  )
}

function* update({ payload: { id, form } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().updateNotice(id, form),
    A.updateNotice,
  )
}

function* grant({ payload: { id, granted, reason } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().grantNotice(id, granted, reason),
    A.grantNotice,
  )
}

function* grantInList({ payload: { id, granted, reason } }) {
  const result = yield call(
    simpleAPI,
    () => API().grantNotice(id, granted, reason),
    A.grantNotice,
  )
  if (result) {
    const param = yield select(selectNoticeList.param);
    yield call(getList, { payload: param })
  }
}

function* deleteNotice({ payload: id }) {
  const result = yield call(
    simpleAPI,
    () => API().deleteNotice(id),
    A.deleteNotice,
  )
  if (result) {
    yield put(goBack())
  }
}

function* deleteNoticeInList({ payload: id }) {
  const result = yield call(
    simpleAPI,
    () => API().deleteNotice(id),
    A.grantNoticeInList,
  )
  if (result) {
    const param = yield select(selectNoticeList.param);
    yield call(getList, { payload: param })
  }
}

export default function* rootSaga() {
  yield throttle(500, `${A.getNoticeList}`, getList)
  yield takeEvery(`${A.postNotice}`, post)
  yield takeEvery(`${A.getNotice}`, getInfo)
  yield takeEvery(`${A.updateNotice}`, update)
  yield takeEvery(`${A.grantNotice}`, grant)
  yield takeEvery(`${A.deleteNotice}`, deleteNotice)
  yield takeEvery(`${A.deleteNoticeInList}`, deleteNoticeInList)
  yield takeEvery(`${A.grantNoticeInList}`, grantInList)
}
