import { select, takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { SiteUrls as SU } from '../routes/siteUrl';
import { LOGIN_START, LOGIN_ERROR, LOGIN_COMPLETE } from '../actions/authAction';
import { selectLocation, selectPreviousLocation, selectRouteAction } from '../selectors/routerSelector';
import { selectToken } from '../selectors/authSelector'
import API from './apiClient';
import { apiErrorHandler } from './common'
import { REHYDRATE } from 'redux-persist/es/constants'

function* backToPrevious() {
  const routeAction = yield select(selectRouteAction);
  const previous = yield select(selectPreviousLocation);
  const location = yield select(selectLocation);

  yield new Promise(resolve => {
    setTimeout(resolve, 100)
  })

  if (routeAction === 'REPLACE' && previous.pathname && previous.pathname !== location.pathname) {
    console.log(`Transition to ${previous.pathname}`)
    yield put(replace(previous.pathname))

  } else {

    console.log(`Transition to default (${SU.Dashboard.Dashboard})`)
    yield put(replace(SU.Dashboard.Dashboard))
  }
}

function* loginProgress({ payload }) {
  
  const { id, password } = payload;
  if (!id) {
    yield call(apiErrorHandler, { message: 'No user defined' })
    yield put(LOGIN_ERROR())
    return
  }
  if (!password) {
    yield call(apiErrorHandler, { message: 'No password' })
    yield put(LOGIN_ERROR())
    return
  }

  console.log(`Login Progress for user ${id}`)

  try {
    const result = yield API().login(id, password);
    API().setToken(result.data.token);
    yield put(LOGIN_COMPLETE(result.data));
    yield call(backToPrevious)

  } catch (e) {
    yield call(apiErrorHandler, e)
    yield put(LOGIN_ERROR())
  }
}

function* setToken() {
  const token = yield select(selectToken)
  API().setToken(token)
}

export default function* rootSaga() {
  yield takeLatest(`${LOGIN_START}`, loginProgress)
  yield takeEvery(`${REHYDRATE}`, setToken)
}
