import API from './apiClient'
import { replace } from 'connected-react-router'
import { call, put, throttle, takeLatest, takeEvery, select } from 'redux-saga/effects'
import * as A from '../actions/version'
import { simpleAPI } from './common'
import { selectFwPaginationApi } from '../selectors/version'
import { SiteUrls } from '../routes/siteUrl'

function* firmwareList({ payload: { page, pageSize, sort, filter } }) {
  yield call(
    simpleAPI,
    () => API().getFirmwareList(page, pageSize, sort, filter),
    A.getFirmwareList,
  )
}

function* updateClientVersion({ payload }) {
  yield call(
    simpleAPI,
    () => API().setClientVersion(payload),
    A.updateClientVersion,
  )
}

function* removeFirmware({ payload: id }) {
  const result = yield call(
    simpleAPI,
    () => API().removeFirware(id),
    A.removeFirmwareVersion,
  )
  if (result) {
    const { page, pageSize, sort, filter } = yield select(selectFwPaginationApi.param)
    yield put(A.getFirmwareList(page, pageSize, sort, filter))
  }
}

function* getClientVersion() {
  yield call(
    simpleAPI,
    () => API().getClientVersion(),
    A.getClientVersion,
  )
}

function* createFirmware({ payload: { type, version, versionString, file } }) {
  const result = yield call(
    simpleAPI,
    () => API().createFirmware(type, version, versionString, file),
    A.createFirmwareVersion,
  )
  if (result) {
    yield put(replace(SiteUrls.Dashboard.Firmware.List))
  }
}

export default function* rootSaga() {
  yield throttle(500, A.getFirmwareList, firmwareList)
  yield takeLatest(A.updateClientVersion, updateClientVersion)
  yield takeEvery(A.removeFirmwareVersion, removeFirmware)
  yield takeLatest(A.getClientVersion, getClientVersion)
  yield takeEvery(A.createFirmwareVersion, createFirmware)
}
