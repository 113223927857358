import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import { apiInitialState, createApiHandler } from './common'
import * as A from '../actions/workout'

export const NAME_WORKOUT = {
  ROOT: 'wo',
  TR_CONF: 'tr_conf',
}

const initialState = fromJS({
  [NAME_WORKOUT.TR_CONF]: apiInitialState(),
})

const handlers = {
  ...createApiHandler(A.getTreadmillConfiguration, NAME_WORKOUT.TR_CONF),
  ...createApiHandler(A.updateTreadmillConfiguration, NAME_WORKOUT.TR_CONF),
}

export default createReducer(handlers, initialState)
