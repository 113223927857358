import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import { apiInitialState, createApiHandler } from './common'
import * as A from '../actions/dashboard'

export const NAME_DASHBOARD = {
  ROOT: 'dashboard',
  GROUP: 'group',
  WORKOUT: 'workout'
}

const initialState = fromJS({
[NAME_DASHBOARD.GROUP]: apiInitialState(),
  [NAME_DASHBOARD.WORKOUT]: apiInitialState(),
})

const handlers = Object.assign({},
  createApiHandler(A.getDashboardAuthGroup, NAME_DASHBOARD.GROUP),
  createApiHandler(A.putDashboardAuthGroup, NAME_DASHBOARD.GROUP),
  createApiHandler(A.deleteDashboardAuthGroup, NAME_DASHBOARD.GROUP),
  createApiHandler(A.getDailyWorkout, NAME_DASHBOARD.WORKOUT),
)


export default createReducer(handlers, initialState)
