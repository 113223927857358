import { createAction } from 'redux-act'

export const NOTI_TYPE = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
}

export const ENQUEUE_NOTIFICATION = createAction('ENQUEUE_NOTIFICATION', (message, type='default') => ({
  type,
  message,
  key: new Date().getTime() + Math.random()
}))
export const REMOVE_NOTIFICATION = createAction('REMOVE_NOTIFICATION')
