import { makeErrDef as E } from './common'

/**
 * @mixin
 */
const exchangeError = {
  EXCHANGE_INVALID_GROUP: E(412, 75100, 'Key is duplicated'),
  EXCHANGE_NOT_FOUND: E(412, 75002, 'Exchange not found'),
  EXCHANGE_STATUS: E(412, 75001, 'Operation is not allowed for this status'),
  EXCHANGE_LOCK_FAIL: E(412, 75200, 'Exchange request not exist or already locked'),
  EXCHANGE_NOT_OWNED: E(403, 75201, 'Exchange transaction can only be performed by person who locked the request'),
  EXCHANGE_LESS_THAN_MINIMUM: E(412, 75006, 'Request amount is less than the minimum'),
  EXCHANGE_TRANSACTION_FAIL: E(412, 75202, 'Fail to make transaction'),
  EXCHANGE_NOT_ENOUGH_POINT: E(412, 75003, 'Not enough point to transaction'),
  EXCHANGE_NO_WALLET: E(412, 75004, 'Wallet is not defined'),
  EXCHANGE_INVALID_RATE: E(412, 75005, 'Rate value is not valid. Ask to trading manager'),
}

export default exchangeError
