import { makeErrDef as E } from './common'

/**
 * @mixin
 */
const userError = {
  USER_NOT_FOUND: E(404, 70000, 'UserId is not exists'),
  DUPLICATED_EMAIL: E(412, 70001, 'Email is duplicated'),
  INVALID_EMAIL: E(412, 70002, 'Email is not valid from'),
  USER_BOUND_ANOTHER_EMAIL: E(412, 70003, 'User has bound to another email'),
  USER_BOUND_AUTH: E(403, 70004, 'User has bound to authorization method'),
  USER_BOUND_ANOTHER_KAKAO: E(412, 70201, 'User has already bound to another kakao'),
  USER_KAKAO_DUPLICATED: E(412, 70202, 'Kakao ID has already bound to other account'),
  USER_KAKAO_FAIL_TO_VERIFY: E(401, 70203, 'Fail to verify kakao token'),
  INVALID_PASSWORD: E(401, 70100, 'Invalid password'),
  INVALID_MONETARY_PASSWORD: E(401, 70102, 'Monetary password should be 6 digits number'),
  NO_MONETARY_PASSWORD: E(412, 70103, 'Have not set the monetary password'),
  TOO_SHORT_PASSWORD: E(412, 70101, 'Password too short'),
}

export default userError
