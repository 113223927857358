import { makeErrDef as E } from './common'

/**
 * @mixin
 */
const noticeError = {
  NOTICE_NOT_FOUND: E(404, 84000, 'Notice not found'),
  NOTICE_EXPIRED: E(410, 84001, 'Notice is expired'),
  NOTICE_NOT_OWNED: E(403, 84002, 'Do not own the notice. Required higher privilege'),
}

export default Object.freeze(noticeError)
