import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import { apiInitialState, paginationInitialState, createApiHandler, createPaginationHandler } from './common'
import * as A from '../actions/version'

export const NAME_VERSION = {
  ROOT: 'version',
  CLIENT: 'client',
  FW: 'fw',
  FW_LIST: 'fwList',
}

const initialState = fromJS({
  [NAME_VERSION.CLIENT]: apiInitialState(),
  [NAME_VERSION.FW]: apiInitialState(),
  [NAME_VERSION.FW_LIST]: paginationInitialState(),
})

const handlers = {
  ...createPaginationHandler(A.getFirmwareList, NAME_VERSION.FW_LIST),
  ...createApiHandler(A.createFirmwareVersion, NAME_VERSION.FW),
  ...createApiHandler(A.removeFirmwareVersion, NAME_VERSION.FW),
  ...createApiHandler(A.getClientVersion, NAME_VERSION.CLIENT),
  ...createApiHandler(A.updateClientVersion, NAME_VERSION.CLIENT),
}

export default createReducer(handlers, initialState)
