import API from './apiClient'
import { call, takeLatest } from 'redux-saga/effects'
import * as A from '../actions/workout'
import { simpleAPI } from './common'

function* getTreadmillConf() {
  yield call(
    simpleAPI,
    () => API().getTreadmillConf(),
    A.getTreadmillConfiguration
  )
}

function* updateTreadmillConf({ payload: { denominator, numerator, maxTime, maxPoint, maxDistance } }) {
  yield call(
    simpleAPI,
    () => API().updateTreadmillConf(denominator, numerator, maxPoint, maxTime, maxDistance),
    A.updateTreadmillConfiguration
  )
}

export default function* workoutSaga() {
  yield takeLatest(A.getTreadmillConfiguration, getTreadmillConf)
  yield takeLatest(A.updateTreadmillConfiguration, updateTreadmillConf)
}
