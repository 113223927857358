import { createSelector } from 'reselect';

const selectAuth = state => state.auth
const isAuthProgressing = createSelector(selectAuth, auth => auth.get('loginProgress'))
const selectAuthUser = createSelector(selectAuth, auth => auth.get('user'))
const selectAuthUserId = createSelector(selectAuthUser, user => (user && user.get('uniqueId')) || null)
const selectAuthUserJS = createSelector(selectAuthUser, user => (user && user.toJS()) || {})
const selectAuthUserName = createSelector(selectAuthUser, user => (user && (user.get('name') || user.get('nickname'))) || 'No name')
const selectAuthUserProfile = createSelector(selectAuthUser, user => (user && user.get('profileImage')) || '')
const selectAuthUserProfileThumb = createSelector(selectAuthUser, user => (user && user.get('profileThumbnail')) || '')
const selectToken = createSelector(selectAuth, auth => auth.get('token'))
const isLogin = createSelector(selectToken, token => !!token)
const isAuthError = createSelector(selectAuth, auth => auth.get('error') !== null)
const selectAuthError = createSelector(selectAuth, auth => auth.get('error').toJS())
export {
  selectAuth,
  isAuthProgressing,
  selectAuthUser,
  selectAuthUserId,
  selectAuthUserJS,
  selectAuthUserName,
  selectAuthUserProfile,
  selectAuthUserProfileThumb,
  selectToken,
  isAuthError,
  selectAuthError,
  isLogin,
}
