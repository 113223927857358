import { fromJS } from 'immutable'
import { createReducer } from 'redux-act'
import { ENQUEUE_NOTIFICATION, REMOVE_NOTIFICATION } from '../actions/notificationAction'

const initialState = fromJS([])

export default createReducer({
  [ENQUEUE_NOTIFICATION]: (state, { type, message, key }) => state
    .push({ type, message, key }),
  [REMOVE_NOTIFICATION]: (state, key) => state
    .filter(m => m.key !== key)
}, initialState)
