import { createSelector } from 'reselect'
import { createApiSelector, createInfoSelectorMaker, createPaginationSelectors } from './common'
import { NAME_POINT as NAME } from '../reducers/points'

export const selectPoint = state => state[NAME.ROOT]
export const selectPointList = createPaginationSelectors(
  createSelector(selectPoint, point => point.get(NAME.LIST)))
export const makeSelectPointInfo = createInfoSelectorMaker(
  createSelector(selectPoint, point => point.get(NAME.DETAIL)))
export const selectExchangeGroup = createApiSelector(
  createSelector(selectPoint, point => point.get(NAME.GROUP)))
export const selectExchangeRate = createApiSelector(
  createSelector(selectPoint, point => point.get(NAME.RATE)))
export const selectDailyReport = createApiSelector(
  createSelector(selectPoint, point => point.get(NAME.DAILY_REPORT)))
