import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import {
  apiInitialState,
  createApiHandler,
  createInfoApiHandler,
  createPaginationHandler,
  infoApiInitialState,
  paginationInitialState
} from './common'
import * as A from '../actions/store'

export const NAME_STORE = {
  ROOT: 'shop',
  AUTH_GROUPS: 'auth',
  NEW_ITEM: 'newItem',
  ITEM_INFO: 'items',
  CATEGORY_LIST: 'categoryList',
  VENDOR_LIST: 'vendorList',
  ITEM_LIST: 'itemList',
  IS_MANAGER: 'isManager',
  UPDATES_IN_LIST: 'updatesInList',
  DELETE_OPTION: 'delOption',
}

const initialState = fromJS({
  [NAME_STORE.AUTH_GROUPS]: apiInitialState(),
  [NAME_STORE.NEW_ITEM]: apiInitialState(),
  [NAME_STORE.ITEM_INFO]: infoApiInitialState(),
  [NAME_STORE.CATEGORY_LIST]: apiInitialState(),
  [NAME_STORE.VENDOR_LIST]: apiInitialState(),
  [NAME_STORE.ITEM_LIST]: paginationInitialState(),
  [NAME_STORE.IS_MANAGER]: apiInitialState(),
  [NAME_STORE.UPDATES_IN_LIST]: apiInitialState(),
  [NAME_STORE.DELETE_OPTION]: apiInitialState(),
})

const handlers = {
  ...createApiHandler(A.getStoreManagerAuthGroup, NAME_STORE.AUTH_GROUPS),
  ...createApiHandler(A.insertStoreManagerAuthGroup, NAME_STORE.AUTH_GROUPS),
  ...createApiHandler(A.removeFromStoreManagementAuthGroup, NAME_STORE.AUTH_GROUPS),
  ...createApiHandler(A.createNewStoreItem, NAME_STORE.NEW_ITEM),
  ...createInfoApiHandler(A.getStoreItem, NAME_STORE.ITEM_INFO),
  ...createInfoApiHandler(A.updateStoreItem, NAME_STORE.ITEM_INFO),
  ...createInfoApiHandler(A.uploadStoreItemImage, NAME_STORE.ITEM_INFO),
  ...createApiHandler(A.getCategoryList, NAME_STORE.CATEGORY_LIST),
  ...createApiHandler(A.getMyVendorList, NAME_STORE.VENDOR_LIST),
  ...createPaginationHandler(A.getStoreItemList, NAME_STORE.ITEM_LIST),
  ...createApiHandler(A.isManager, NAME_STORE.IS_MANAGER),
  ...createApiHandler(A.deleteItemInList, NAME_STORE.UPDATES_IN_LIST),
  ...createApiHandler(A.banItemInList, NAME_STORE.UPDATES_IN_LIST),
  ...createApiHandler(A.setSaleInList, NAME_STORE.UPDATES_IN_LIST),
  ...createInfoApiHandler(A.deleteMainImage, NAME_STORE.ITEM_INFO),
  ...createInfoApiHandler(A.deleteDescriptionImage, NAME_STORE.ITEM_INFO),
  ...createInfoApiHandler(A.addItemOption, NAME_STORE.ITEM_INFO),
  ...createInfoApiHandler(A.updateItemOption, NAME_STORE.ITEM_INFO),
  ...createApiHandler(A.deleteItemOption, NAME_STORE.DELETE_OPTION),
}

export default createReducer(handlers, initialState)
