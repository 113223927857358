import common from './common'
import user from './user'
import AppError from './appError'
import version from './version'
import gym from './gym'
import notice from './notice'
import device from './device'
import workout from './workout'
import ad from './ad'
import authGroup from './authGroup'
import exchange from './exchange'

const errDefs = [
  common, // 60xxx
  user, // 70xxx
  gym, // 71xxx
  device, // 72xxx
  workout, // 73xxx
  authGroup, // 74xxx
  exchange, // 75xxx
  notice, // 84xxx
  ad, // 85xxx
  version, // 87xxx
]

/**
 * @typedef {object} ERR
 * @mixes {commonError}
 * @mixes {userError}
 * @mixes {versionError}
 * @mixes {gymError}
 * @mixes {deviceError}
 * @mixes {noticeError}
 * @mixes {workoutError}
 * @mixes {adError}
 * @mixes {authError}
 * @mixes {exchangeError}
 */
const errorFinal = Object.freeze(Object.assign({}, ...errDefs))

export default errorFinal
export { AppError }
