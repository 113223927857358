import { createApiAction, createInfoApiAction, createPaginationAction } from './common'

export const getOrderManagerAuthGroup = createApiAction('getOrderManagerAuthGroup')
export const insertOrderManagerAuthGroup = createApiAction('insertOrderManagerAuthGroup')
export const removeFromOrderManagementAuthGroup = createApiAction('removeFromOrderManagementAuthGroup')
export const getOrderItemList = createPaginationAction('getOrderItemList')
export const getOrderDeliveryItem = createInfoApiAction('getOrderDeliveryItem', (id) => ({ id }))
export const updateOrderDeliveryItem = createInfoApiAction('updateOrderDeliveryItem', (id, data) => ({ id, data }))
export const getDeliveryCarriers = createApiAction('getDeliveryCarriers')
export const orderPaymentCancel = createInfoApiAction('orderPaymentCancel', (id) => ({ id }))
