import { makeErrDef as E } from './common'

/**
 * @mixin
 */
const deviceError = {
  DEVICE_DUPLICATED_SERIAL: E(412, 72000, 'Device ID is duplicated'),
  DEVICE_NOT_FOUND: E(404, 72001, 'Device not found'),
  DEVICE_ALREADY_ASSIGNED: E(412, 72002, 'Device is already assigned'),
  TABLET_NOT_FOUND: E(404, 72100, 'Tablet not found'),
}

export default deviceError
