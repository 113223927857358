import { createApiAction, createPaginationAction, createInfoApiAction } from './common'

export const getTradingGroup = createApiAction('getTradingGroup')
export const TradingGroupType = {
  MANAGER: 'manager',
  TRADING: 'trading',
}
export const putTradingGroup = createApiAction('putTradingGroup',
  (groupType, key) => ({ groupType, key }))
export const removeFromTradingGroup = createApiAction('removeFromTradingGroup',
  (groupType, key) => ({ groupType, key }))
export const getTradingRequestList = createPaginationAction('getTradingRequestList')
export const getTradingRequestDetail = createInfoApiAction('getTradingRequestDetail',
  (id) => ({ id }))
export const getTradingRate = createApiAction('getTradingRate')
export const setTradingRate = createApiAction('setTradingRate')
export const transactRequest = createInfoApiAction('transactRequest', (id) => ({ id }))
export const rejectRequest = createInfoApiAction('rejectRequest', (id, reason) => ({ id, reason }))
export const lockRequest = createInfoApiAction('lockRequest', (id) => ({ id }))
export const dailyTransactionReport = createApiAction('dailyTransactionReport')

