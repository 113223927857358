export const GrantStatus = {
  WAITING: 1,
  GRANTED: 2,
  REJECTED: -1,
}

export const NoticeType = {
  NORMAL: 1,
  POPUP: 2,
}

export const AdFilterType = {
  AGE: 1,
  GENDER: 2,
  LOCATION: 3,
  WEIGHT: 4,
  HEIGHT: 5,
  BMI: 6,
}

export const Gender = {
  MALE: 1,
  FEMALE: 2,
}

export const AuthLevel = {
  DENIED: 0,
  READER: 1,
  WRITER: 2,
  MASTER: 3,
}

export const ExchangeStatus = {
  REQUESTED: 1,
  LOCKED: 2,
  RESOLVED: 200,
  REJECTED: 403,
}

export const AuthGroupType = {
  ADMIN: 1,
  FINANCE: 2,
  STORE: 3,
  SPONSOR: 4,
  GYM: 5,
  QA: 6,
}

export const ChallengeParticipationType = {
  GLOBAL: 1,
  INDIVIDUAL: 2,
}

export const ChallengeType = {
  TREADMILL: 1,
  BIKE: 2,
  POINT: 3,
}

export const ChallengeRewardType = {
  POINT: 1,
  CASH: 2,
  GOODS: 3,
}

export const ChallengeRankType = {
  POINT: 1,
  CAL: 2,
  DISTANCE: 3,
  DURATION: 4,
  COUNT: 1,
}

export const StatType = {
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
  ALL: 4,
}

export const TabletStatus = {
  OFFLINE: 1,
  ONLINE: 2,
}

export const SensorStatus = {
  OFFLINE: 1,
  ONLINE: 2,
}

export const AuthType = {
  DEVICE: 1,
  EMAIL: 2,
  KAKAO: 3,
  APPLE: 4,
  QR: 5,
  PHONE: 6,
  GOOGLE: 7,
  CREDENTIAL: 99,
}

export const DeviceType = {
  TREADMILL: 1,
  BIKE: 2,
}

export const NotificationType = {
  NOTICE: 1,
  EVENT: 2,
  QUESTION: 3,
  DELIVERY: 4,
}


export const ChallengeCategory ={
  WEEKLY: 1,
  MONTHLY: 2,
}

export const PedalControlType = {
  KEY: 1,
  ONOFF: 2,
}

export const FirmwareType = {
  TREADMILL: 1,
  PEDAL: 2,
  JOYSTICK: 3,
}

