import { createSelector } from 'reselect'

export const selectRouter = (state) => state.router;
export const selectRouteAction = createSelector(selectRouter, (router) => router.get('action'))
export const selectLocation = createSelector(selectRouter, (router) => router.get('location'))
export const selectPreviousLocation = createSelector(selectLocation, (location) => {
  const state = location.get('state')
  if (!state) {
    return {}
  }
  const target = location.get('target')
  return (target && target.toJS()) || {}
})
