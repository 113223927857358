import { isLogin as selectIsLogin } from '../selectors/authSelector'

let store = null

export function setStore(s) {
  store = s
}

export function isLogin() {
  return !!(store && selectIsLogin(store.getState()))
}
