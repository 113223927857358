import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router/immutable';
import notificationReducer from './notificationReducer';
import authReducer from './authReducer';
import noticeReducer, { NAME_NOTICE } from './noticeReducer';
import versionReducer, { NAME_VERSION } from './version';
import adReducer, { NAME_AD } from './advertisement';
import authGroupReducer, { NAME_AUTH } from './authGroup'
import pointReducer, { NAME_POINT } from './points'
import globalReducer from './global'
import userReducer, { NAME_USER } from './user'
import workoutReducer, { NAME_WORKOUT } from './workout'
import storeReducer, { NAME_STORE } from './store'
import deviceReducer, {NAME_DEVICE} from './device'
import gymReducer, { NAME_GYM } from './gym'
import dashboardReducer, {NAME_DASHBOARD} from './dashboard'
import orderReducer, {NAME_ORDER} from './order'

export default function createRootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    global: globalReducer,
    notification: notificationReducer,
    auth: authReducer,
    [NAME_NOTICE.ROOT]: noticeReducer,
    [NAME_VERSION.ROOT]: versionReducer,
    [NAME_AD.ROOT]: adReducer,
    [NAME_AUTH.ROOT]: authGroupReducer,
    [NAME_POINT.ROOT]: pointReducer,
    [NAME_USER.ROOT]: userReducer,
    [NAME_WORKOUT.ROOT]: workoutReducer,
    [NAME_STORE.ROOT]: storeReducer,
    [NAME_DEVICE.ROOT]: deviceReducer,
    [NAME_GYM.ROOT]: gymReducer,
    [NAME_DASHBOARD.ROOT]: dashboardReducer,
    [NAME_ORDER.ROOT]: orderReducer,
  });
}
