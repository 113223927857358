import L from 'react-loadable';

import LoadingPage from './LoadingPage.js'

const Loadable = opts =>
  L({
    loading: LoadingPage,
    delay: 300,
    timeout: 10000,
    ...opts
  })

export default Loadable
