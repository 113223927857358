import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import {
  apiInitialState,
  paginationInitialState,
  createApiHandler,
  createPaginationHandler,
  infoApiInitialState,
  createInfoApiHandler
} from './common'
import * as A from '../actions/device'

export const NAME_DEVICE = {
  ROOT: 'device',
  DV: 'dv',
  LIST: 'list',
  INFO: 'info',
}

const initialState = fromJS({
  [NAME_DEVICE.DV]: apiInitialState(),
  [NAME_DEVICE.LIST]: paginationInitialState(),
  [NAME_DEVICE.INFO]: infoApiInitialState(),
})

const handlers = Object.assign({},
  createApiHandler(A.addDevice, NAME_DEVICE.DV),
  createPaginationHandler(A.getDeviceList, NAME_DEVICE.LIST),
  createInfoApiHandler(A.getDeviceInfo, NAME_DEVICE.INFO),
  createInfoApiHandler(A.updateDeviceInfo, NAME_DEVICE.INFO),
  createApiHandler(A.deleteDevice, NAME_DEVICE.DV)
)

export default createReducer(handlers, initialState)