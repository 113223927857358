import API from './apiClient'
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects'
import * as A from '../actions/order'
import { simpleAPI, simpleInfoAPI } from './common'
import { OrderStatus } from '../views/Order/common'
import { ENQUEUE_NOTIFICATION, NOTI_TYPE } from '../actions/notificationAction'

function* getAuthGroup() {
  yield call(
    simpleAPI,
    () => API().getOrderManagerAuthGroup(),
    A.getOrderManagerAuthGroup,
  )
}

function* putAuthGroup({ payload }) {
  yield call(
    simpleAPI,
    () => API().insertOrderManagerAuthGroup(payload),
    A.insertOrderManagerAuthGroup,
  )
}

function* pullAuthGroup({ payload }) {
  yield call(
    simpleAPI,
    () => API().removeFromOrderManagementAuthGroup(payload),
    A.removeFromOrderManagementAuthGroup,
  )
}

function* getOrderItemList({ payload }) {
  yield call(
    simpleAPI,
    () => API().getOrderList(payload.page, payload.pageSize, payload.sort, payload.filter),
    A.getOrderItemList,
  )
}

function* getOrderDeliveryItem({payload}) {
  const {id} = payload
  yield call(
    simpleInfoAPI,
    id,
    () => API().getOrderDeliveryItem(id),
    A.getOrderDeliveryItem,
  )
}

function* getDeliveryCarriers() {

  yield call(
    simpleAPI,
    () => API().getDeliveryCarriers(),
    A.getDeliveryCarriers,
  )
}

function* updateOrderDeliveryItem({ payload: { id, data }}) {
  const result = yield call(
    simpleInfoAPI,
    id,
    () => API().updateOrderDeliveryItem(id, data),
    A.updateOrderDeliveryItem,
  )
  if(result) {
    if(result.data.status ===  OrderStatus._CANCEL_COMPLETE) {
      console.log('payment cancel')
      const cancelResult = yield call(orderPaymentCancel, { payload: {id : result.data._id} } )
      if(!cancelResult) {
        yield put(ENQUEUE_NOTIFICATION('결제가 취소되었습니다.', NOTI_TYPE.SUCCESS))
      } else {
        yield put(ENQUEUE_NOTIFICATION('결제 취소에 실패하였습니다.', NOTI_TYPE.ERROR))
      }
    } else {
      yield put(ENQUEUE_NOTIFICATION('성공적으로 수정되었습니다.', NOTI_TYPE.SUCCESS))
    }
  } else {
    yield put(ENQUEUE_NOTIFICATION('수정에 실패하였습니다.', NOTI_TYPE.ERROR))
  }
}

function* orderPaymentCancel({payload}) {
  const {id} = payload
  yield call(
    simpleAPI,
    () => API().orderPaymentCancel(id),
    A.orderPaymentCancel,
  )
}

export default function* storeSaga() {
  yield takeLatest(A.getOrderManagerAuthGroup, getAuthGroup)
  yield takeEvery(A.insertOrderManagerAuthGroup, putAuthGroup)
  yield takeEvery(A.removeFromOrderManagementAuthGroup, pullAuthGroup)
  yield takeEvery(A.getOrderItemList, getOrderItemList)
  yield takeEvery(A.getOrderDeliveryItem, getOrderDeliveryItem)
  yield takeEvery(A.getDeliveryCarriers, getDeliveryCarriers)
  yield takeEvery(A.updateOrderDeliveryItem, updateOrderDeliveryItem)
  yield takeEvery(A.orderPaymentCancel, orderPaymentCancel)


}
