import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import {
  createApiHandler,
  createInfoApiHandler,
  apiInitialState,
  infoApiInitialState,
} from './common'
import * as A from '../actions/authGroup'

export const NAME_AUTH = {
  ROOT: 'authGroup',
  INFO: 'info',
  TREE: 'tree',
  CREATE: 'create',
  AUTH_LIST: 'list',
  SEARCH: 'search',
}

const initialState = fromJS({
  [NAME_AUTH.AUTH_LIST]: apiInitialState(),
  [NAME_AUTH.INFO]: infoApiInitialState(),
  [NAME_AUTH.TREE]: apiInitialState(),
  [NAME_AUTH.CREATE]: apiInitialState(),
  [NAME_AUTH.SEARCH]: apiInitialState(),
})

const handlers = Object.assign({},
  createApiHandler(A.createNewGroup, NAME_AUTH.CREATE),
  createInfoApiHandler(A.getInfo, NAME_AUTH.INFO),
  createInfoApiHandler(A.deleteAuth, NAME_AUTH.INFO),
  createInfoApiHandler(A.insertUser, NAME_AUTH.INFO),
  createInfoApiHandler(A.updateUser, NAME_AUTH.INFO),
  createInfoApiHandler(A.removeUser, NAME_AUTH.INFO),
  createApiHandler(A.getAuthTree, NAME_AUTH.TREE),
  createInfoApiHandler(A.getUserAuth, NAME_AUTH.AUTH_LIST),
  createApiHandler(A.searchAuth, NAME_AUTH.SEARCH),
)

export default createReducer(handlers, initialState)
