import { call, takeLatest, takeEvery, throttle, put } from 'redux-saga/effects'
import _ from 'lodash'
import API from './apiClient'
import * as A from '../actions/user'
import { simpleAPI, simpleInfoAPI } from './common'
import { ENQUEUE_NOTIFICATION, NOTI_TYPE } from '../actions/notificationAction'

function* searchUser({ payload: { format, count } }) {
  yield call(
    simpleAPI,
    () => API().searchUser(format, count),
    A.searchUser,
  )
}

function* getUserList({ payload }) {
  const newSort = _.map(payload.sort, (d)  => {
    const checkId = (id) => {
      if (id === 'workoutDuration') return 'workout.duration'
      if (id === 'workoutDistance') return 'workout.distance'
      if (id === 'balanceRemain') return 'balance.remain'
      return id
    }
    return {
      ...d,
      id: checkId(d.id)
    }
  })
  yield call(
    simpleAPI,
    () => API().userList(payload.page, payload.pageSize, newSort, payload.filter),
    A.getUserList,
  )
}

function* getUserInfo({ payload: { id } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().userInfo(id),
    A.getUserInfo,
  )
}

function* updateUserInfo({ payload: { id, form } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().updateUserInfo(id, form),
    A.updateUserInfo,
  )
}

function* changePassword({ payload: { id, data } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().forceChangePassword(id, data.form),
    A.changePassword,
  )
  yield put(ENQUEUE_NOTIFICATION('비밀번호가 변경되었습니다.', NOTI_TYPE.SUCCESS))
}

export default function* rootSaga() {
  yield throttle(1000, A.searchUser, searchUser)
  yield takeLatest(A.getUserList, getUserList)
  yield takeEvery(A.getUserInfo, getUserInfo)
  yield takeEvery(A.updateUserInfo, updateUserInfo)
  yield takeEvery(A.changePassword, changePassword)
}
