import API from './apiClient'
import { replace } from 'connected-react-router'
import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects'
import * as A from '../actions/advertisement'
import { apiErrorHandler, simpleAPI, simpleInfoAPI } from './common'
import { SiteUrls } from '../routes/siteUrl'
import { ENQUEUE_NOTIFICATION, NOTI_TYPE } from '../actions/notificationAction'
import { selectAdList } from '../selectors/advertisement'

function* createAd({ payload: data }) {
  try {
    let result = yield API().createAd(data)
    if (data.mediaFile) {
      try {
        result = yield API().uploadMediaAd(result.data.id, data.mediaFile)
      } catch (e) {
        yield call(apiErrorHandler, e)
        yield put(ENQUEUE_NOTIFICATION('비디오 업로드에 실패하였습니다.'), NOTI_TYPE.ERROR)
      }
    }
    yield put(A.createAdvertisement.complete(result.data))
    yield put(replace(SiteUrls.Dashboard.Advertisements.List))
    yield put(ENQUEUE_NOTIFICATION('새로운 광고가 생성되었습니다.', NOTI_TYPE.SUCCESS))
  } catch (e) {
    yield call(apiErrorHandler, e)
    yield put(A.createAdvertisement.error(e))
  }
}

function* uploadMedia({ payload: { id, media } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().uploadMediaAd(id, media),
    A.uploadMedia,
  )
}

function* getAd({ payload: id }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().getAd(id),
    A.getAdvertisement,
  )
}

function* updateAd({ payload: { id, data } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().updateAd(id, data),
    A.updateAdvertisement,
  )
}

function* removeAd({ payload: id }) {
  const result = yield call(
    simpleAPI,
    () => API().removeAd(id),
    A.removeAdvertisement,
  )
  if (result) {
    const params = yield select(selectAdList.param)
    yield put(A.getAdvertisementList(params))
  }
}

function* createFilter({ payload: { id, data } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().addFilter(id, data),
    A.createFilter,
  )
}

function* removeFilter({ payload: { id, filterKey } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().removeFilter(id, filterKey),
    A.removeFilter,
  )
}

function* updateFilter({ payload: { id, filterKey, data } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().updateFilter(id, filterKey, data),
    A.updateFilter,
  )
}

function* getList({ payload: { page, pageSize, sort, filter } }) {
  yield call(
    simpleAPI,
    () => API().getAdList(page, pageSize, sort, filter),
    A.getAdvertisementList,
  )
}

function* deleteMedia({ payload: id }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().deleteAdMedia(id),
    A.deleteMedia
  )
}

function* getFilters({ payload: id }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().getFilters(id),
    A.getFilters,
  )
}

export default function* rootSaga() {
  yield takeLatest(A.createAdvertisement, createAd)
  yield takeEvery(A.uploadMedia, uploadMedia)
  yield takeEvery(A.deleteMedia, deleteMedia)
  yield takeEvery(A.getAdvertisement, getAd)
  yield takeEvery(A.getFilters, getFilters)
  yield takeLatest(A.updateAdvertisement, updateAd)
  yield takeLatest(A.removeAdvertisement, removeAd)
  yield takeLatest(A.createFilter, createFilter)
  yield takeLatest(A.removeFilter, removeFilter)
  yield takeLatest(A.updateFilter, updateFilter)
  yield takeLatest(A.getAdvertisementList, getList)
}
