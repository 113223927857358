import { createAction } from 'redux-act'

export function createApiAction(name, payloadBuilder) {
  const baseAction = createAction(...arguments)
  baseAction.complete = createAction(`${name}:complete`)
  baseAction.error = createAction(`${name}:error`)
  baseAction.invalidate = createAction(`${name}:invalidate`)
  return baseAction
}

export function createPaginationAction(name) {
  return createApiAction(name, (page, pageSize, sort, filter, data) => ({
    page,
    pageSize,
    sort,
    filter,
    data,
  }))
}

export function createInfoApiAction(name, ...params) {
  const baseAction = createAction(name, ...params)
  baseAction.complete = createAction(`${name}:complete`, (id, data) => ({ id, data }))
  baseAction.error = createAction(`${name}:error`, (id, error) => ({ id, error }))
  baseAction.invalidate = createAction(`${name}:invalidate`, (id) => ({ id }))
  return baseAction
}
