import { call, put } from 'redux-saga/effects'
import { LOG_OUT } from '../actions/authAction'
import { replace } from 'connected-react-router';
import { ENQUEUE_NOTIFICATION, NOTI_TYPE } from '../actions/notificationAction'
import ERR from '../errors'
import { SiteUrls as SU } from '../routes/siteUrl'

export function* apiErrorHandler(e) {
  if (e.response) {
    const { data = {}, statusText = 'unknown error' } = e.response
    const { message = statusText, code } = data
    yield put(ENQUEUE_NOTIFICATION(message, NOTI_TYPE.ERROR))
    if (code === ERR.INVALID_TOKEN.code
      || code === ERR.TOKEN_REQUIRED.code
      || code === ERR.EXPIRED_TOKEN.code) {
      yield put(LOG_OUT())
      yield put(replace(SU.Pages.Login))
    }
  } else {
    // Regard as network error
    yield put(LOG_OUT())
    yield put(replace(SU.Pages.Login))
    yield put(ENQUEUE_NOTIFICATION(e.message || 'Unknown Error', NOTI_TYPE.ERROR))
  }
}

export function* simpleAPI(caller, resultAction) {
  try {
    const result = yield caller()
    yield put(resultAction.complete(result.data))
    return result
  } catch (e) {
    yield call(apiErrorHandler, e)
    const errorData = e.response && e.response.data
    yield put(resultAction.error(errorData))
  }
}

export function* simpleInfoAPI(id, caller, resultAction) {
  try {
    const result = yield caller()
    yield put(resultAction.complete(id, result.data))
    return result
  } catch (e) {
    yield call(apiErrorHandler, e)
    const errorData = e.response && e.response.data
    yield put(resultAction.error(id, errorData))
  }
}
