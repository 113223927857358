import React from 'react';
import { Redirect, Route } from 'react-router-dom';

// eslint-disable-next-line react/display-name
export const createAuthRoute = (loginChecker, loginUrl) => ({ component: Component, ...rest }) => {
  if (rest.path && rest.path === loginUrl) {
    return <Route exact {...rest} component={Component} />
  }
  return <Route {...rest} render={props => {
    return (
      loginChecker() ?
        (<Component {...props} />) :
        (<Redirect to={{ pathname: loginUrl, state: { target: props.location } }} />)
    )
  }} />;
};
