import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'connected-react-router/immutable';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist'
import immutableTransform from 'redux-persist-transform-immutable'
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1'
import storage from 'redux-persist/lib/storage'
import createRootReducer from '../reducers';
import rootSaga from '../sagas';

const persistConfig = {
  key: 'root',
  transforms: [immutableTransform()],
  storage,
  whitelist: ['auth'],
  stateReconciler: autoMergeLevel1,
}

export default function configureStore(initialState, history) {
  const rootReducer = createRootReducer(history);
  const router = routerMiddleware(history);
  const saga = createSagaMiddleware();
  const enhancer = compose(applyMiddleware(router, saga));

  const persistedReducer = persistReducer(persistConfig, rootReducer)
  const store = createStore(persistedReducer, fromJS(initialState), enhancer);
  saga.run(rootSaga);
  let persistor = persistStore(store)
  return { store, persistor } ;
}
