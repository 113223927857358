import assert from 'assert'
import { isFunction, has, isObject } from 'lodash'
import { fromJS } from 'immutable'

export function paginationInitialState() {
  return {
    isLoading: false,
    data: [],
    pages: -1,
    error: null,
    lastSort: [],
    lastFilter: [],
    lastData: [],
    pageSize: undefined,
    page: undefined,
    timestamp: new Date().getTime(),
  }
}

export function apiInitialState() {
  return {
    isInProgressing: false,
    error: null,
    data: null,
    timestamp: new Date().getTime(),
  }
}

export function infoApiInitialState() {
  return fromJS({})
}

export function createPaginationHandler(action, base) {
  assert(isFunction(action.complete))
  assert(isFunction(action.error))

  if (!base) {
    return {
      [action]: (state, payload) => state
        .set('isLoading', true)
        .set('lastSort', fromJS(payload.sort))
        .set('lastFilter', fromJS(payload.filter))
        .set('lastData', fromJS(payload.data))
        .set('page', payload.page)
        .set('error', null)
        .set('pageSize', payload.pageSize),
      [action.complete]: (state, payload) => state
        .set('isLoading', false)
        .set('data', fromJS(payload.docs))
        .set('error', null)
        .set('pages', payload.totalPage)
        .set('timestamp', new Date().getTime()),
      [action.error]: (state, payload) => state
        .set('isLoading', false)
        .set('error', payload)
        .set('data', null)
        .set('pages', -1),
      [action.invalidate]: (state) => state
        .set('isLoading', false)
        .set('error', null)
        .set('data', fromJS([]))
        .set('pages', -1)
        .set('timestamp', new Date().getTime()),
    }
  }

  return {
    [action]: (state, payload) => state
      .setIn([base, 'isLoading'], true)
      .setIn([base, 'lastSort'], fromJS(payload.sort))
      .setIn([base, 'lastFilter'], fromJS(payload.filter))
      .setIn([base, 'lastData'], fromJS(payload.lastData))
      .setIn([base, 'page'], payload.page)
      .setIn([base, 'error'], null)
      .setIn([base, 'pageSize'], payload.pageSize),
    [action.complete]: (state, payload) => state
      .setIn([base, 'isLoading'], false)
      .setIn([base, 'data'], fromJS(payload.docs))
      .setIn([base, 'error'], null)
      .setIn([base, 'pages'], payload.totalPage),
    [action.error]: (state, payload) => state
      .setIn([base, 'isLoading'], false)
      .setIn([base, 'error'], payload)
      .setIn([base, 'data'], null)
      .setIn([base, 'pages'], -1),
    [action.invalidate]: (state) => state
      .setIn([base, 'isLoading'], false)
      .setIn([base, 'error'], null)
      .setIn([base, 'data'], fromJS([]))
      .setIn([base, 'pages'], -1)
      .setIn([base, 'timestamp'], new Date().getTime()),
  }
}

export function createApiHandler(action, base) {
  assert(isFunction(action.complete))
  assert(isFunction(action.error))

  if (!base) {
    return {
      [action]: (state) => state
        .set('isInProgressing', true)
        .set('error', null),
      [action.complete]: (state, payload) => state
        .set('isInProgressing', false)
        .set('error', null)
        .set('data', fromJS(payload))
        .set('timestamp', new Date().getTime()),
      [action.error]: (state, payload) => state
        .set('isInProgressing', false)
        .set('error', payload)
        .set('data', null),
      [action.invalidate]: (state) => state
        .set('isLoading', false)
        .set('error', null)
        .set('data', null),
    }
  }
  return {
    [action]: (state) => state
      .setIn([base, 'isInProgressing'], true)
      .setIn([base, 'error'], null),
    [action.complete]: (state, payload) => state
      .setIn([base, 'isInProgressing'], false)
      .setIn([base, 'error'], null)
      .setIn([base, 'data'], fromJS(payload))
      .setIn([base, 'timestamp'], new Date().getTime()),
    [action.error]: (state, payload) => state
      .setIn([base, 'isInProgressing'], false)
      .setIn([base, 'error'], payload)
      .setIn([base, 'data'], null),
    [action.invalidate]: (state) => state
      .setIn([base, 'isLoading'], false)
      .setIn([base, 'error'], null)
      .setIn([base, 'data'], null)
  }
}

export function createInfoApiHandler(action, base) {
  assert(isFunction(action.complete))
  assert(isFunction(action.error))
  assert(base)
  return {
    [action]: (state, payload) => {
      let id = payload
      if (isObject(payload) && has(payload, 'id')) {
        id = payload.id
      }
      return state
        .setIn([base, id, 'isInProgressing'], true)
        .setIn([base, id, 'error'], null)
    },
    [action.complete]: (state, { id, data }) => {
      assert(id)
      return state.setIn([base, id, 'isInProgressing'], false)
        .setIn([base, id, 'error'], null)
        .setIn([base, id, 'data'], fromJS(data))
        .setIn([base, id, 'timestamp'], new Date().getTime())
    },
    [action.error]: (state, { id, error }) => state
      .setIn([base, id, 'isInProgressing'], false)
      .setIn([base, id, 'error'], error),
    [action.invalidate]: (state, { id }) => {
      assert(id)
      return state
        .deleteIn([base, id])
    }
  }
}
