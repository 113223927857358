function makeIdQuery(base, postQuery = '') {
  return (id) => `${base}${id ? `?id=${id}` : ''}${postQuery}`
}

export const SiteUrls = {
  Dashboard: {
    Main: '/',
    Dashboard: '/dashboard',
    Challenge: '/challenge',
    Me: '/me',
    Gym: {
      _: '/gym',
      New:'/gym/new',
      List: '/gym/list',
      Detail: makeIdQuery('/gym/detail'),
    },
    Stat: {
      _: '/stat',
      Gym: '/stat/byGym',
      Region: '/stat/byRegion',
    },
    User: {
      _: '/user',
      List: '/user/list',
      Detail: makeIdQuery('/user/detail'),
      Workout: makeIdQuery('/user/workout'),
      New: '/user/create',
      Merge: '/user/merge',
    },
    Notice: {
      _: '/notice',
      List: '/notice/list',
      Detail: makeIdQuery('/notice/detail'),
      Edit: makeIdQuery('/notice/edit'),
      New: '/notice/new',
    },
    Firmware: {
      _: '/fw',
      List: '/fw/list',
      New: '/fw/new',
    },
    Device: {
      _: '/device',
      List: '/device/list',
      New: '/device/new',
      // AddDevices: '/device/addDevices',
      Detail: makeIdQuery('/device/detail'),
    },
    Configurations: {
      _: '/conf',
      versions: '/conf/version',
      workout: '/conf/workout',
      bike: '/conf/bike/workout',
      DashAuth: '/conf/dashAuth',
    },
    Auth: {
      _: '/auth',
      Detail: makeIdQuery('/notice/detail'),
    },
    ApiDocuments: {
      _: '/api'
    },
    Advertisements: {
      _: '/ad',
      List: '/ad/list',
      New: '/ad/new',
      Detail: makeIdQuery('/ad/detail'),
    },
    Point: {
      _: '/point',
      List: '/point/list',
      Detail: makeIdQuery('/point/detail'),
      Config: '/point/config',
      DailyReport: '/point/report/daily'
    },
    Store: {
      _: '/store',
      ItemNew: '/store/item/new',
      ItemList: '/store/item/list',
      ItemDetail: makeIdQuery('/store/item/detail'),
      Config: '/store/config',
    },
    Order: {
      _: '/order',
      ItemList: '/order/item/list',
      ItemDetail: makeIdQuery('/order/item/detail'),
      Config: '/order/config',
    },
    Cs: {
      _: '/cs',
      List: '/cs/list',
      Detail: makeIdQuery('/cs/detail'),
    },
    Terms: {
      _: '/terms',
      List: '/terms/list',
      Detail: makeIdQuery('/terms/detail'),
      New: '/terms/new',
      Edit: makeIdQuery('/terms/id'),
    },
    Event: {
      _: '/event',
      Detail: makeIdQuery('/event/detail'),
      Edit: makeIdQuery('/event/edit'),
      List: '/event/list',
      New: '/event/new',
    },
    Refresh: '/admin/refresh',
    Tablet: {
      _: '/tablet',
      List: '/tablet/list',
      Detail: makeIdQuery('/tablet/detail'),
    },
    AppCategory: {
      _: '/category',
      List: '/category/list',
      New: '/category/new',
      Detail: makeIdQuery('/category/detail'),
      Edit: makeIdQuery('/category/edit'),
    },
    Application: {
      _: '/application',
      List: '/application/list',
      New: '/application/new',
      Detail: makeIdQuery('/application/detail'),
      Edit: makeIdQuery('/application/edit'),
    },
    Unregister: {
      _: '/unregisterReason',
      List: '/unregisterReason/list',
      Detail: makeIdQuery('/unregisterReason/detail'),
      Edit: makeIdQuery('/unregisterReason/edit'),
      New: '/unregisterReason/new',
    },
    QaPage:{
      _: '/qaPage',
    },
    Ranking: {
      _: '/ranking',
      Treadmill: '/ranking/treadmill',
      Bike: '/ranking/bike',
    }
  },
  Pages: {
    Main: '/_',
    Login: '/_/login',
    FindPassword: '/_/findPassword',
    Refresh: '/_/refresh',
  },
};
