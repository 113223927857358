import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import * as A from '../actions/gym'
import API from './apiClient'
import { simpleAPI, simpleInfoAPI } from './common'
import { SiteUrls } from '../routes/siteUrl'
import { ENQUEUE_NOTIFICATION, NOTI_TYPE } from '../actions/notificationAction'

//gym 등록
function* createGym({ payload }) {
  const { name, phoneNumber, address } = payload
  if (!name) {
    yield put(ENQUEUE_NOTIFICATION('매장 명을 입력하세요.', NOTI_TYPE.ERROR))
      return
  }
  if (!phoneNumber) {
    yield put(ENQUEUE_NOTIFICATION('대표전화를 입력하세요 입력하세요.', NOTI_TYPE.ERROR))
    return
  }
  if (!address) {
    yield put(ENQUEUE_NOTIFICATION('도로명 주소를 입력하세요.', NOTI_TYPE.ERROR))
    return
  }
  const result = yield call(
    simpleAPI,
    () => API().createGym(payload),
    A.createGym,
  )
  if (result) {
    yield put(replace(SiteUrls.Dashboard.Gym.List))
    yield put(ENQUEUE_NOTIFICATION('새로운 Gym이 등록되었습니다.', NOTI_TYPE.SUCCESS))
  }
}

//gym 리스트
function* getGymList({ payload }) {
  yield call(
    simpleAPI,
    () => API().getGymList(payload.page, payload.pageSize, payload.sort, payload.filter),
    A.getGymList,
  )
}

//gym 상세 정보
function* getGymDetail({ payload: { id } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().getGymDetail(id),
    A.getGymDetail,
  )
}

//gym 상세 정보 update
function* updateGymDetail({ payload: { id, data } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().updateGymDetail(id, data),
    A.updateGymDetail,
  )
}

//gym 삭제
function* deleteGym({ payload: id }) {
  yield  call(
    simpleAPI,
    () => API().deleteGym(id),
    A.deleteGym,
  )
  yield put(A.getGymList())
}

export default function* rootSaga() {
  yield takeEvery(A.createGym, createGym)
  yield takeLatest(A.getGymList, getGymList)
  yield takeEvery(A.getGymDetail, getGymDetail)
  yield takeLatest(A.updateGymDetail, updateGymDetail)
  yield takeLatest(A.deleteGym, deleteGym)
}
