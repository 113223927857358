import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { createBrowserHistory } from 'history';
import App from './App';
import configureStore from './store/configureStore';
import './assets/scss/material-dashboard-pro-react.scss';
import { setStore } from './utils/loginChecker'

const history = createBrowserHistory();
const { store, persistor } = configureStore({}, history);
setStore(store)

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App history={history} />
    </PersistGate>
  </Provider>,
  document.getElementById('root'));

if (module.hot) {
  module.hot.accept('./App', () => {
    render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App store={store} history={history} />
        </PersistGate>
      </Provider>,
      document.getElementById('root')
    );
  });
}
