import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import {
  apiInitialState,
  createInfoApiHandler,
  createPaginationHandler,
  infoApiInitialState,
  paginationInitialState
} from './common'
import * as A from '../actions/noticeAction'

export const NAME_NOTICE = {
  ROOT: 'notice',
  LIST: 'list',
  INFO: 'info',
  POST: 'post',
}

const initialState = fromJS({
  [NAME_NOTICE.LIST]: paginationInitialState(),
  [NAME_NOTICE.INFO]: infoApiInitialState(),
  [NAME_NOTICE.POST]: apiInitialState(),
})

const handlers = Object.assign({},
  createPaginationHandler(A.getNoticeList, NAME_NOTICE.LIST),
  createInfoApiHandler(A.getNotice, NAME_NOTICE.INFO),
  createInfoApiHandler(A.updateNotice, NAME_NOTICE.INFO),
  createInfoApiHandler(A.grantNotice, NAME_NOTICE.INFO),
  apiInitialState(A.postNotice, NAME_NOTICE.POST),
)

export default createReducer(handlers, initialState)
