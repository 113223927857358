import _ from 'lodash'
import moment from 'moment'

export const OrderStatus = {
  ORDERED: 1,
  PAYED: 2,
  ACCEPTED: 3,
  PROGRESS: 4,
  COMPLETE: 8,
  _CANCEL_REQUESTED: 16,
  CANCEL_BY_USER: 17,
  CANCEL_BY_ADMIN: 18,
  _CANCEL_ACCEPTED: 19,
  _CANCEL_COMPLETE: 20,
}

export const OrderStatusType = [
  { value: OrderStatus.ORDERED, item: '주문중' },
  { value: OrderStatus.PAYED, item: '주문완료' },
  { value: OrderStatus.ACCEPTED, item: '주문수락' },
  { value: OrderStatus.PROGRESS, item: '배송처리중' },
  { value: OrderStatus.COMPLETE, item: '판매완료' },
  { value: OrderStatus._CANCEL_REQUESTED, item: '주문취소요청' },
  { value: OrderStatus._CANCEL_ACCEPTED, item: '주문취소수락' },
  { value: OrderStatus._CANCEL_COMPLETE, item: '주문취소완료' },
]

export const DeliveryStatus = {
  PREPAIRING: 1,
  STARTED: 2,
  ARRIVED: 3,
  CANCEL_RETURNING: 11,
  CANCEL_RETURNED: 12,
  EXCHANGE_RETURNING: 21,
  EXCHANGE_RESEND: 22,
  EXCHANGE_ARRIVED: 23,
}

export const DeliveryStatusType = [
  { value: DeliveryStatus.PREPAIRING, item: '준비중' },
  // { value: DeliveryStatus.STARTED, item: '배송시작' },
  { value: DeliveryStatus.ARRIVED, item: '배송완료' },
  // { value: DeliveryStatus.CANCEL_RETURNING, item: '환불 수거중' },
  // { value: DeliveryStatus.CANCEL_RETURNED, item: '환불 완료' },
  // { value: DeliveryStatus.EXCHANGE_RETURNING, item: '교환 수거중' },
  // { value: DeliveryStatus.EXCHANGE_RESEND, item: '교환 수거 완료 및 재배송' },
  // { value: DeliveryStatus.EXCHANGE_ARRIVED, item: '교환 완료' },
]

export const OrderCancelType = [
  { value: '0', item: '내용없음' },
  { value: '1', item: '결제 취소' },
  { value: '2', item: '교환' },
  { value: '3', item: '환불' },
]

export const OrderStatusStyle = {
  [OrderStatus.COMPLETE]: {
    color: 'green'
  },
  [OrderStatus._CANCEL_REQUESTED]: {
    color: 'red'
  },
}

function makeDateString(date) {
  return date ? moment(date).format('YYYY-MM-DD') : ''
}

function makeItemInfo(items) {
  if (items.length > 0) {
    let dispString = items[0].name
    if (items[0].options.length) {
      dispString = dispString + ' (옵션:' + items[0].options.map(({ name }) => `${name}`).join() + ')'
    }
    dispString = `${dispString} (${items[0].count}개)`
    if (items.length > 1) {
      dispString = `${dispString}  외 ${items.length - 1}건`
    }
    return dispString
  }
  return ''
}

function makeItemList(items) {
  if (items.length > 0) {
    return items.map((item, index) =>
      ({
        itemName:
          item.name +
          ((item.options.length) ?
            ' (옵션:' + item.options.map(({ name }) => `${name} `).join() + ')' : ''),
        count: item.count,
        price: item.netCost
      }))
  }
  return ''
}

function makePaymentMethod(data) {
  if (data && data.method === 2) return '모바일'
  return ''
}

export const formData = (doc) => {
  if (!doc) return {}

  doc.createdAt = makeDateString(doc.createdAt)
  doc.resolvedAt = makeDateString(doc.resolvedAt)
  doc.price = doc.sum.toLocaleString()
  doc.pointUsed = doc.pointUseRequest && doc.pointUseRequest.toLocaleString()
  doc.discountPriceSum = doc.discountPriceSum && doc.discountPriceSum.toLocaleString()
  doc.amount = doc.charged && doc.charged.toLocaleString()
  doc.itemInfo = makeItemInfo(doc.items)
  doc.orderItemList = makeItemList(doc.items)
  doc.orderStatus = _.get(doc, 'recentDeliveryStatus.orderStatus')
  doc.deliveryStatus = doc.deliveryStatus
  doc.cancelType = _.get(doc, 'recentDeliveryStatus.cancelType')

  if (_.has(doc, 'receipt')) {
    doc.paymentMethod = makePaymentMethod(doc.receipt)
    if (_.has(doc.receipt, 'data')) {
      doc.payedCardName = doc.receipt.data.card_name
      doc.payedPGId = doc.receipt.data.imp_uid
      doc.payedOrderId = doc.receipt.data.merchant_uid
      doc.payedMethod = doc.receipt.data.pay_method
      doc.payedPGTid = doc.receipt.data.pg_tid
      doc.payedPGProvider = doc.receipt.data.pg_provider
    }
  }

  if (_.has(doc, 'recentDeliveryStatus')) {
    if (_.has(doc.recentDeliveryStatus, 'deliveryInfo')) {
      doc.deliveryName = doc.recentDeliveryStatus.deliveryInfo.name
      doc.deliveryPhone = doc.recentDeliveryStatus.deliveryInfo.phone
      doc.deliveryAddress = doc.recentDeliveryStatus.deliveryInfo.address
      doc.deliveryRequirement = doc.recentDeliveryStatus.deliveryInfo.requirement
      doc.deliveryCarrierId = doc.recentDeliveryStatus.deliveryCarrierId
      doc.deliveryCarrierName = doc.recentDeliveryStatus.deliveryCarrierName
    }

    if (_.has(doc.recentDeliveryStatus, 'deliveryInvoice')) {
      doc.deliveryInvoice = doc.recentDeliveryStatus.deliveryInvoice
    }

    if (_.has(doc.recentDeliveryStatus, 'recallInfo')) {
      doc.deliveryRecallRequirement = doc.recentDeliveryStatus.recallInfo.requirement
      doc.deliveryRecallName = doc.recentDeliveryStatus.recallInfo.name
      doc.deliveryRecallPhone = doc.recentDeliveryStatus.recallInfo.phone
      doc.deliveryRecallAddress = doc.recentDeliveryStatus.recallInfo.address
      doc.recallCarrierId = doc.recentDeliveryStatus.recallCarrierId
      doc.recallCarrierName = doc.recentDeliveryStatus.recallCarrierName
    }

    if (_.has(doc.recentDeliveryStatus, 'deliveryInvoice')) {
      doc.recallInvoice = doc.recentDeliveryStatus.recallInvoice
    }
    doc.deliveryStatusMemo = doc.recentDeliveryStatus
  }
  return doc
}

export const listData = (doc) => {
  if (!doc) {
    return {}
  }
  doc.createdAt = makeDateString(doc.createdAt)
  doc.resolvedAt = makeDateString(doc.resolvedAt)
  doc.sum = doc.sum.toLocaleString()
  doc.pointUsed = doc.pointUsed
  doc.discountPriceSum = doc.discountPriceSum && doc.discountPriceSum.toLocaleString()
  doc.amount = doc.charged && doc.charged.toLocaleString()
  doc.itemInfo = makeItemInfo(doc.items)
  doc.orderItemList = makeItemList(doc.items)
  doc.status = _.get(doc, 'status')
  doc.deliveryStatus = doc.deliveryStatus
  doc.cancelType = _.get(doc, 'recentDeliveryStatus.cancelType')
  doc.status = _.get(doc, 'status')
  return doc
}

