import API from './apiClient'
import { replace } from 'connected-react-router'
import { put, call, takeEvery, takeLatest, select } from 'redux-saga/effects'
import * as A from '../actions/store'
import { SiteUrls } from '../routes/siteUrl'
import { apiErrorHandler, simpleAPI, simpleInfoAPI } from './common'
import { ENQUEUE_NOTIFICATION, NOTI_TYPE } from '../actions/notificationAction'
import { selectItemList } from '../selectors/store'
import { formData } from '../views/Store/common'

function* getAuthGroup() {
  yield call(
    simpleAPI,
    () => API().getStoreManagerAuthGroup(),
    A.getStoreManagerAuthGroup,
  )
}

function* putAuthGroup({ payload }) {
  yield call(
    simpleAPI,
    () => API().insertStoreManagerAuthGroup(payload),
    A.insertStoreManagerAuthGroup,
  )
}

function* pullAuthGroup({ payload }) {
  yield call(
    simpleAPI,
    () => API().removeFromStoreManagementAuthGroup(payload),
    A.removeFromStoreManagementAuthGroup,
  )
}

function* getVendorList() {

  yield call(
    simpleAPI,
    () => API().getMyVendorList(),
    A.getMyVendorList,
  )
}

function* getCategoryList() {
  yield call(
    simpleAPI,
    () => API().getCategoryList(),
    A.getCategoryList,
  )
}

function* createItem({ payload: { name, categoryId, vendorKey } }) {
  if (name && name.length > 16) {
    yield put(ENQUEUE_NOTIFICATION('상품명은 최대 16글자까지 입력하세요.', NOTI_TYPE.ERROR))
    return
  }
  const result = yield call(
    simpleAPI,
    () => API().createNewStoreItem(name, categoryId, vendorKey),
    A.getCategoryList,
  )
  if (result) {
    yield put(replace(SiteUrls.Dashboard.Store.ItemList))
    yield put(ENQUEUE_NOTIFICATION('새로운 상점 아이템이 생성되었습니다.', NOTI_TYPE.SUCCESS))
  }
}

function* getItemList({ payload }) {
  yield call(
    simpleAPI,
    () => API().getStoreItemListForAdmin(payload.data, payload.page, payload.pageSize, payload.sort, payload.filter),
    A.getStoreItemList,
  )
}

function* isManager() {
  yield call(
    simpleAPI,
    () => API().isStoreManager(),
    A.isManager,
  )
}

function* callApisThenUpdateList(resultAction, api) {
  try {
    const result = yield call(api)
    const value = yield call(simpleAPI, () => API().isStoreManager(), A.isManager)
    if (value.data.isManager) {
      const params = yield select(selectItemList.param)
      yield call(getItemList, { payload: { params } })
      yield put(resultAction.complete(result.data))
      return result
    } else {
      const params = yield select(selectItemList.param)
      const lastdata = yield select(selectItemList.make(data => data.map(formData)))
      const nextValue = lastdata.data[0].vendor
      yield call(getItemList, { payload: { params, data: nextValue } })
      yield put(resultAction.complete(result.data))
      return result
    }
  } catch (e) {
    yield call(apiErrorHandler, e)
    const errorData = e.response && e.response.data
    yield put(resultAction.error(errorData))
  } finally {
  }
}

function* handleUpdate({ type, payload }) {
  switch (type) {
    case `${A.deleteItemInList}`:
      yield call(callApisThenUpdateList, A.deleteItemInList, () => API().removeStoreItem(payload.id))
      break
    case `${A.banItemInList}`:
      yield call(callApisThenUpdateList, A.banItemInList, () => API().updateStoreItem(payload.id, { banned: payload.banned }))
      break
    case `${A.setSaleInList}`:
      yield call(callApisThenUpdateList, A.setSaleInList, () => API().updateStoreItem(payload.id, { onSale: payload.onSale }))
      break
    default:
      console.error(`Unexpected action type ${type}`)
      break
  }
}

//아이템 상세정보
function* getStoreItem({ payload: { id } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().getStoreItem(id),
    A.getStoreItem,
  )
}

//아이템 업데이트
function* updateStoreItem({ payload: { id, data } }) {
  if (data && data.title && data.title.length > 16) {
    yield put(ENQUEUE_NOTIFICATION('상품명은 최대 16글자까지 입력하세요.', NOTI_TYPE.ERROR))
    return
  }
  const result = yield call(
    simpleInfoAPI,
    id,
    () => API().updateStoreItem(id, data),
    A.updateStoreItem,
  )
  if (result) {
    yield put(ENQUEUE_NOTIFICATION('아이템 정보가 수정되었습니다.', NOTI_TYPE.SUCCESS))
  } else {
    yield put(ENQUEUE_NOTIFICATION('아이템 정보 수정에 실패하였습니다.', NOTI_TYPE.ERROR))
  }
}

//이미지 업로드
function* uploadStoreItemImage({ payload: { id, main, description } }) {
  yield  call(
    simpleInfoAPI,
    id,
    () => API().uploadStoreItemImage(id, main, description),
    A.uploadStoreItemImage
  )
}

//이미지 삭제
function* deleteMainImage({ payload: { id } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().deleteMainImage(id),
    A.deleteMainImage,
  )
}

function* deleteDescriptionImage({ payload: { id, imageId } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().deleteDescriptionImage(id, imageId),
    A.deleteDescriptionImage,
  )
}

//옵션 추가
function* addItemOption({ payload: { id, optionId, data } }) {
  if(data && data.description && data.description.length > 16) {
    yield put(ENQUEUE_NOTIFICATION('옵션명은 최대 16글자까지 입력하세요.', NOTI_TYPE.ERROR))
    return
  }
  const newData = {
    ...data,
    optionRequired: data.optionRequired === 'true',
  }
  const result = yield call(
    simpleInfoAPI,
    id,
    () => API().addItemOption(optionId, newData),
    A.addItemOption,
  )
  if (result) {
    yield put(ENQUEUE_NOTIFICATION('옵션이 추가되었습니다.', NOTI_TYPE.SUCCESS))
  }
}

//아이템 옵션 업데이트
function* updateItemOption({ payload: { id, optionId, data } }) {
  if(data && data.description && data.description.length > 16) {
    yield put(ENQUEUE_NOTIFICATION('옵션명은 최대 16글자까지 입력하세요.', NOTI_TYPE.ERROR))
    return
  }
  yield call(
    simpleInfoAPI,
    id,
    () => API().updateItemOption(optionId, data),
    A.updateItemOption,
  )
}

//아이템 옵션 삭제
function* deleteItemOption({ payload: { id, optionId } }) {
  const result = yield call(
    simpleAPI,
    () => API().deleteItemOption(optionId),
    A.deleteItemOption,
  )
  //return값이 아이템이 아니라서 storeItem을 한번 더 호출해줌
  if (result) {
    yield put(A.getStoreItem(id))
  }
}

export default function* storeSaga() {
  yield takeLatest(A.getStoreManagerAuthGroup, getAuthGroup)
  yield takeEvery(A.insertStoreManagerAuthGroup, putAuthGroup)
  yield takeEvery(A.removeFromStoreManagementAuthGroup, pullAuthGroup)
  yield takeLatest(A.getMyVendorList, getVendorList)
  yield takeLatest(A.getCategoryList, getCategoryList)
  yield takeEvery(A.createNewStoreItem, createItem)
  yield takeLatest(A.getStoreItemList, getItemList)
  yield takeLatest(A.isManager, isManager)
  yield takeLatest([A.deleteItemInList, A.banItemInList, A.setSaleInList], handleUpdate)
  yield takeEvery(A.getStoreItem, getStoreItem)
  yield takeEvery(A.updateStoreItem, updateStoreItem)
  yield takeLatest(A.uploadStoreItemImage, uploadStoreItemImage)
  yield takeLatest(A.deleteMainImage, deleteMainImage)
  yield takeLatest(A.deleteDescriptionImage, deleteDescriptionImage)
  yield takeLatest(A.addItemOption, addItemOption)
  yield takeLatest(A.updateItemOption, updateItemOption)
  yield takeLatest(A.deleteItemOption, deleteItemOption)
}
