import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import GridContainer from '../../components/Grid/GridContainer'
import GridItem from '../../components/Grid/GridItem'

const LoadingPage = () => (
  <GridContainer justify="center" alignItems="center">
    <GridItem>
      <CircularProgress
        style={{
          display: 'inline-block',
          color: '#ddd',
        }}
        variant="indeterminate"
      />
    </GridItem>
  </GridContainer>
)

export default LoadingPage
