import { makeErrDef as E } from './common'

/**
 * @mixin
 */
const gymError = {
  GYM_NOT_FOUND: E(404, 71000, 'Gym is not exists'),
  GYM_CONTRACT_EXPIRED: E(410, 71001, 'Contract is expired'),
}

export default gymError
