import { makeErrDef as E } from './common'

/**
 * @mixin
 */
const workoutError = {
  WORKOUT_NOT_FOUND: E(404, 73000, 'Workout not found'),
  WORKOUT_STATUS: E(412, 73001, 'Current action is not valid with current workout status'),
  WORKOUT_NOT_OWNED: E(412, 73002, 'Workout is not owned to device'),
  WORKOUT_OTHER_IN_PROGRESS: E(412, 73003, 'Another workout in progress'),
  WORKOUT_USER_TIMEOUT: E(410, 73004, 'Elapsed too long.'),
  WORKOUT_CONFIG_ERROR: E(412, 73999, 'Weird configuration. Contact to admin'),
}

export default workoutError
