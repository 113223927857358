import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import {
  apiInitialState,
  paginationInitialState,
  infoApiInitialState,
  createApiHandler,
  createPaginationHandler,
  createInfoApiHandler
} from './common'
import * as A from '../actions/gym'

export const NAME_GYM = {
  ROOT: 'gym',
  POST: 'post',
  LIST: 'list',
  DETAIL: 'detail',
  DELETE: 'delete',
}

const initialState = fromJS({
  [NAME_GYM.POST]: apiInitialState(),
  [NAME_GYM.LIST]: paginationInitialState(),
  [NAME_GYM.DETAIL]: infoApiInitialState(),
  [NAME_GYM.DELETE]: apiInitialState(),
})

const handlers = Object.assign({},
  createApiHandler(A.createGym, NAME_GYM.POST),
  createPaginationHandler(A.getGymList, NAME_GYM.LIST),
  createInfoApiHandler(A.getGymDetail, NAME_GYM.DETAIL),
  createInfoApiHandler(A.updateGymDetail, NAME_GYM.DETAIL),
  createApiHandler(A.deleteGym, NAME_GYM.DELETE),
)

export default createReducer(handlers, initialState)