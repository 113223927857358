import { createSelector } from 'reselect';
import { createApiSelector, createInfoSelectorMaker, createPaginationSelectors } from './common';
import { NAME_AD as NAME } from '../reducers/advertisement'

export const selectAd = state => state[NAME.ROOT]
export const selectAdList = createPaginationSelectors(createSelector(selectAd, ad => ad.get(NAME.LIST)))
export const makeSelectAdInfo = createInfoSelectorMaker(createSelector(selectAd, ad => ad.get(NAME.INFO)))
export const makeSelectAdFilter = createInfoSelectorMaker(createSelector(selectAd, ad => ad.get(NAME.FILTER)))
export const selectAdCreate = createApiSelector(createSelector(selectAd, ad => ad.get(NAME.CREATE)))
export const selectAdDelete = createApiSelector(createSelector(selectAd, ad => ad.get(NAME.DELETE)))
