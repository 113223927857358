import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { REMOVE_NOTIFICATION } from '../../actions/notificationAction'

class Notifier extends React.PureComponent {
  displayed = []

  storeDisplayed = id => this.displayed = [...this.displayed, id]

  componentDidUpdate() {
    const { notifications } = this.props
    if (notifications) {
      notifications.toJS().forEach(notification => {
        // Do nothing if snackbar is already displayed
        if (this.displayed.includes(notification.key)) return
        // Display snackbar using notistack
        this.props.enqueueSnackbar(notification.message, { variant: notification.type })
        // Keep track of snackbars that we've displayed
        this.storeDisplayed(notification.key)
        // Dispatch action to remove snackbar from redux store
        this.props.REMOVE_NOTIFICATION(notification.key)
      })
    }
  }

  render() {
    return null
  }
}

const mapStateToProps = state => ({
  notifications: state.notification,
})

const mapDispatchToProps = dispatch => bindActionCreators({ REMOVE_NOTIFICATION }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(Notifier))
