import { call, takeEvery, takeLatest } from 'redux-saga/effects'
import API from './apiClient'
import * as A from '../actions/dashboard'
import {simpleAPI} from './common'

function* getDashboardAuthGroup(){
  yield call(
    simpleAPI,
    () => API().getDashboardAuthGroup(),
    A.getDashboardAuthGroup,
  )
}

function* putDashboardAuthGroup({payload: {key}}){
  yield call(
    simpleAPI,
    () => API().putDashboardAuthGroup(key),
    A.putDashboardAuthGroup,
  )
}

function* deleteDashboardAuthGroup({payload: {key}}){
  yield call(
    simpleAPI,
    () => API().deleteDashboardAuthGroup(key),
    A.deleteDashboardAuthGroup,
  )
}

function* getDailyWorkout({payload:{date}}){
  yield call(
    simpleAPI,
    () => API().getDailyWorkout(date),
    A.getDailyWorkout,
  )
}
export default function* rootSaga(){
  yield takeEvery(A.getDashboardAuthGroup, getDashboardAuthGroup)
  yield takeLatest(A.putDashboardAuthGroup, putDashboardAuthGroup)
  yield takeLatest(A.deleteDashboardAuthGroup, deleteDashboardAuthGroup)
  yield takeEvery(A.getDailyWorkout, getDailyWorkout)
}