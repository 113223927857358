import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { replace } from 'connected-react-router'

import * as A from '../actions/device'
import { ENQUEUE_NOTIFICATION, NOTI_TYPE } from '../actions/notificationAction'
import { SiteUrls } from '../routes/siteUrl'

import API from './apiClient'
import { simpleAPI, simpleInfoAPI } from './common'

function* addDevice({ payload }) {
  const result = yield call(
    simpleAPI,
    () => API().addDevice(payload),
    A.addDevice,
  )
  if (result) {
    yield put(replace(SiteUrls.Dashboard.Device.List))
    yield put(ENQUEUE_NOTIFICATION('디바이스가 추가되었습니다.', NOTI_TYPE.SUCCESS))

  }
}

function* getDeviceList({ payload }) {
  yield call(
    simpleAPI,
    () => API().getDeviceList(payload.page, payload.pageSize, payload.sort, payload.filter),
    A.getDeviceList,
  )
}

function* getDeviceInfo({ payload: { id } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().getDeviceInfo(id),
    A.getDeviceInfo,
  )
}

function* updateDeviceInfo({ payload: { id, data } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().updateDeviceInfo(id, data),
    A.updateDeviceInfo,
  )
}

function* deleteDevice({ payload: id }) {
  yield call(
    simpleAPI,
    () => API().deleteDevice(id),
    A.deleteDevice,
  )
  yield put(A.getDeviceList())
}

export default function* rootSaga() {
  yield takeLatest(A.addDevice, addDevice)
  yield takeEvery(A.getDeviceList, getDeviceList)
  yield takeEvery(A.getDeviceInfo, getDeviceInfo)
  yield takeLatest(A.updateDeviceInfo, updateDeviceInfo)
  yield takeLatest(A.deleteDevice, deleteDevice)
}