import { createApiAction, createInfoApiAction } from './common'

/**
 * @type {Function}
 * @param {String}Key
 * @param {String}Parent
 */
export const createNewGroup = createApiAction('createNewGroup', (key, parent, type) => ({ key, parent, type }))
/**
 * @type {Function}
 * @param {String}id
 */
export const getInfo = createInfoApiAction('getInfo', (id) => ({ id }))
/**
 * @type {Function}
 * @prarm {String}id
 */
export const deleteAuth = createInfoApiAction('deleteAuth', (id) => ({ id }))
export const insertUser = createInfoApiAction('insertUser', (id, user, level) => ({ id, user, level }))
export const updateUser = createInfoApiAction('updateUser', (id, user, level) => ({ id, user, level }))
export const removeUser = createInfoApiAction('removeUser', (id, user) => ({ id, user }))
export const getAuthTree = createApiAction('getAuthTree')
export const getUserAuth = createInfoApiAction('getUserAuth', (id) => ({ id }))
export const searchAuth = createApiAction('searchAuth', (format, count) => ({ format, count }))
