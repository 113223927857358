import { makeErrDef as E } from './common'

/**
 * @mixin
 */
const adError = {
  AD_NOT_FOUND: E(404, 85000, 'Advertisement is not found'),
  AD_FILTER_NOT_FOUND: E(404, 85101, 'Filter not found'),
  AD_RECOMMEND_CONDITION: E(412, 85002, 'Cannot perform recommendation with current condition'),
  AD_NO_AVAILABLE: E(410, 85003, 'No available advertisement'),
}

export default adError
