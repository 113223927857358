import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import {
  createApiHandler,
  createInfoApiHandler,
  createPaginationHandler,
  apiInitialState,
  infoApiInitialState,
  paginationInitialState,
} from './common'
import * as A from '../actions/advertisement'

export const NAME_AD = {
  ROOT: 'ad',
  LIST: 'list',
  INFO: 'info',
  FILTER: 'filter',
  CREATE: 'create',
  DELETE: 'del_ad',
}

const initialState = fromJS({
  [NAME_AD.LIST]: paginationInitialState(),
  [NAME_AD.INFO]: infoApiInitialState(),
  [NAME_AD.FILTER]: infoApiInitialState(),
  [NAME_AD.CREATE]: apiInitialState(),
  [NAME_AD.DELETE]: apiInitialState(),
})

const handlers = Object.assign({},
  createPaginationHandler(A.getAdvertisementList, NAME_AD.LIST),
  createInfoApiHandler(A.uploadMedia, NAME_AD.INFO),
  createInfoApiHandler(A.getAdvertisement, NAME_AD.INFO),
  createInfoApiHandler(A.updateAdvertisement, NAME_AD.INFO),
  createInfoApiHandler(A.deleteMedia, NAME_AD.INFO),
  createApiHandler(A.removeAdvertisement, NAME_AD.DELETE),
  createApiHandler(A.createAdvertisement, NAME_AD.CREATE),
  createInfoApiHandler(A.createFilter, NAME_AD.FILTER),
  createInfoApiHandler(A.getFilters, NAME_AD.FILTER),
  createInfoApiHandler(A.removeFilter, NAME_AD.FILTER),
  createInfoApiHandler(A.updateFilter, NAME_AD.FILTER),
)

export default createReducer(handlers, initialState)
