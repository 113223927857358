import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import {
  apiInitialState,
  createApiHandler,
  createInfoApiHandler,
  createPaginationHandler,
  infoApiInitialState,
  paginationInitialState
} from './common'
import * as A from '../actions/order'
import { NAME_STORE } from './store'

export const NAME_ORDER = {
  ROOT: 'order',
  AUTH_GROUPS: 'auth',
  ITEM_INFO: 'items',
  ITEM_LIST: 'itemList',
  IS_MANAGER: 'isManager',
  UPDATES_IN_LIST: 'updatesInList',
  DELIVERY_CARRIERS: 'deliveryCarriers'
}


const initialState = fromJS({
  [NAME_ORDER.AUTH_GROUPS]: apiInitialState(),
  [NAME_ORDER.ITEM_INFO]: infoApiInitialState(),
  [NAME_ORDER.VENDOR_LIST]: apiInitialState(),
  [NAME_ORDER.ITEM_LIST]: paginationInitialState(),
  [NAME_ORDER.IS_MANAGER]: apiInitialState(),
  [NAME_ORDER.UPDATES_IN_LIST]: apiInitialState(),
  [NAME_ORDER.DELIVERY_CARRIERS]: apiInitialState(),
})


const handlers = {
  ...createApiHandler(A.getOrderManagerAuthGroup, NAME_ORDER.AUTH_GROUPS),
  ...createApiHandler(A.insertOrderManagerAuthGroup, NAME_ORDER.AUTH_GROUPS),
  ...createApiHandler(A.removeFromOrderManagementAuthGroup, NAME_ORDER.AUTH_GROUPS),
  ...createPaginationHandler(A.getOrderItemList, NAME_ORDER.ITEM_LIST),
  ...createInfoApiHandler(A.getOrderDeliveryItem, NAME_ORDER.ITEM_INFO),
  ...createInfoApiHandler(A.updateOrderDeliveryItem, NAME_STORE.ITEM_INFO),
  ...createApiHandler(A.getDeliveryCarriers, NAME_ORDER.DELIVERY_CARRIERS),
}

export default createReducer(handlers, initialState)
