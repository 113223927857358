import { createApiAction, createInfoApiAction, createPaginationAction } from './common'

export const getNoticeList = createPaginationAction('getNoticeList');
export const getNotice = createInfoApiAction('getNotice');
export const postNotice = createApiAction('postNotice');
export const updateNotice = createInfoApiAction('updateNotice', (id, form) => ({ id, form }))
export const deleteNotice = createApiAction('deleteNotice')
export const deleteNoticeInList = createApiAction('deleteNoticeInList')
export const grantNotice = createInfoApiAction('grantNotice', (id, granted, reason) => ({ id, granted, reason }))
export const grantNoticeInList = createApiAction('grantNoticeInList', (id, granted, reason) => ({
  id,
  granted,
  reason
}))
