import axios from 'axios'
import _ from 'lodash'
import { chain, isEmpty } from 'lodash'
import { AuthLevel } from '../const'

let staticClient

class ApiClient {
  static createPaginationOption(page, pageMax, sort, filter) {
    const sorted = chain(sort)
      .keyBy('id')
      .mapValues(o => o.desc ? -1 : 1)
      .value()
    const filtered = chain(filter)
      .keyBy('id')
      .mapValues(o => o.value)
      .value()
    return {
      page: page || 0,
      pageMax: pageMax || 20,
      sort: isEmpty(sorted) ? undefined : sorted,
      filter: isEmpty(filtered) ? undefined : filtered
    }
  }

  constructor() {
    this._axios = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })
  }

  setToken(token) {
    this._axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  setCredential(key) {
    this._axios.defaults.headers.credential = key
  }

  login(email, pw) {
    return this._axios.post('/user/login/email', { email, pw })
  }

  noticeList( { page, pageMax, filter, sort } = {}) {
    return this._axios.post('/notification/list', { page, pageMax, filter, sort })
  }

  userList(page, pageMax, sort, filter) {
    const options = ApiClient.createPaginationOption(...arguments)
    return this._axios.post('/user/list', options)
  }

  /*유저 정보 가져오기*/
  userInfo(id) {
    return this._axios.get(`/user/${id}`)
  }

  /*유저 정보 업데이트*/
  updateUserInfo(id, data) {
    return this._axios.put(`/user/${id}`, data)
  }

  postNotice(data) {
    return this._axios.post('/notification', data)
  }

  getNotice(id) {
    return this._axios.get(`/notification/${id}`)
  }

  updateNotice(id, data) {
    return this._axios.put(`/notification/${id}`, data)
  }

  grantNotice(id, granted, reason) {
    return this._axios.put(`/notice/${id}/grant`, { granted, reason })
  }

  deleteNotice(id) {
    return this._axios.delete(`/notification/${id}`)
  }

  getFirmwareList(...args) {
    const options = ApiClient.createPaginationOption(...args)
    return this._axios.post('/version/device/list', options)
  }

  createFirmware(type, version, versionString, file) {
    const form = new FormData();
    if (type === undefined || version === undefined || file === undefined) {
      throw new Error('Invalid arguments!')
    }
    form.append('type', `${type}`)
    form.append('version', `${version}`)
    form.append('file', file)
    if (versionString) {
      form.append('versionString', `${versionString}`)
    }
    return this._axios.post('/version/device', form,
      { headers: { 'content-type': 'multipart/form-data' } });
  }

  removeFirware(id) {
    return this._axios.delete(`/version/device/${id}`)
  }

  getClientVersion() {
    return this._axios.get('/version/client/workout')
  }

  setClientVersion(data) {
    return this._axios.put('/version/client/workout', data)
  }

  createAd(data) {
    return this._axios.post('/ad/new', data)
  }

  uploadMediaAd(adId, media) {
    const form = new FormData();
    form.append('media', media)
    return this._axios.put(`/ad/media/${adId}`, form, { headers: { 'content-type': 'multipart/form-data' } })
  }

  getAd(adId) {
    return this._axios.get(`/ad/info/${adId}`)
  }

  removeAd(adId) {
    return this._axios.delete(`/ad/info/${adId}`)
  }

  updateAd(adId, data) {
    return this._axios.put(`/ad/info/${adId}`, data)
  }

  addFilter(adId, data) {
    return this._axios.post(`/ad/filter/${adId}`, data)
  }

  getFilters(adId) {
    return this._axios.get(`/ad/filter/${adId}`)
  }

  removeFilter(adId, filterKey) {
    return this._axios.delete(`/ad/filter/${adId}/${filterKey}`)
  }

  updateFilter(adId, filterKey, data) {
    return this._axios.put(`/ad/filter/${adId}/${filterKey}`, data)
  }

  getAdList(...args) {
    const options = ApiClient.createPaginationOption(...args)
    return this._axios.post('/ad/list/all', options)
  }

  deleteAdMedia(adId) {
    return this._axios.delete(`/ad/media/${adId}`)
  }

  createAuth(key, parent, type) {
    return this._axios.post('/auth/new', { key, parent, type })
  }

  getAuthInfo(key) {
    return this._axios.get('/auth/info', { params: { key } })
  }

  deleteAuth(key) {
    return this._axios.delete('/auth/info', { params: { key } })
  }

  insertUserToAuth(key, user, level = AuthLevel.READER) {
    return this._axios.post('/auth/user', { user, level }, { params: { key } })
  }

  updateUserLevel(key, user, level) {
    return this._axios.put('/auth/user', { level }, { params: { key, user } })
  }

  removeUserFromAuth(key, user) {
    return this._axios.delete('/auth/user', { params: { key, user } })
  }

  getAuthTree() {
    return this._axios.get('/auth/tree')
  }

  searchAuth(format, count = 20) {
    return this._axios.get('/auth/search', { params: { f: format, c: count } })
  }

  authListOfUser(user, level = AuthLevel.READER) {
    return this._axios.get('/auth/list', { params: { user, level } })
  }

  getPointAuthGroup() {
    return this._axios.get('/point/auth/group')
  }

  insertPointAuthGroup(groupType, key) {
    return this._axios.post(`/point/auth/group/${groupType}`, { group: key })
  }

  removePointAuthGroup(groupType, key) {
    return this._axios.delete(`/point/auth/group/${groupType}`, { params: { group: key } })
  }

  getExchangeRequestList(...args) {
    const options = ApiClient.createPaginationOption(...args)
    return this._axios.post('/point/list/sell/request', options)
  }

  lockExchangeRequest(id) {
    return this._axios.put('/point/lock/request', null, { params: { id } })
  }

  getExchangeRequestInfo(id) {
    return this._axios.get('/point/detail/sell/request', { params: { id } })
  }

  getExchangeRate() {
    return this._axios.get('/point/exchange/rate')
  }

  setExchangeRate(rate) {
    return this._axios.put('/point/exchange/rate', { rate })
  }

  transactRequest(id) {
    return this._axios.put('/point/transact/request', null, { params: { id } })
  }

  searchUser(format, count) {
    return this._axios.get('/user/search/email', { params: { f: format, c: count } })
  }

  searchUserWhole(format, count) {
    return this._axios.get('/user/search/whole', { params: { f: format, c: count } })
  }

  getTreadmillConf() {
    return this._axios.get('/config/common/rate')
  }

  updateTreadmillConf(denominator, numerator, maxPoint, maxTime, maxDistance) {
    /*
    {
      "denominator": 0,
      "numerator": 0,
      "maxPoint": 0,
      "maxTime": 0
    }
    */
    return this._axios.put('/config/common/rate', { denominator, numerator, maxPoint, maxTime, maxDistance })
  }

  getStoreManagerAuthGroup() {
    return this._axios.get('/store/auth/manager')
  }

  insertStoreManagerAuthGroup(group) {
    return this._axios.put('/store/auth/manager', null, { params: { group } })
  }

  removeFromStoreManagementAuthGroup(group) {
    return this._axios.delete('/store/auth/manager', { params: { group } })
  }

  createCategory(name, parent) {
    return this._axios.post('/store/category/new', { title: name, parent })
  }

  getCategoryInfo(id) {
    return this._axios.get('/store/category/info', { params: { id } })
  }

  //새로운 판매자를 생성
  createNewVendor() {
    return this._axios.post('/store/vendor/new')
  }

  getMyVendorList() {
    return this._axios.get('/store/vendor/mine')
  }

  createNewStoreItem(name, categoryId, vendorKey) {
    return this._axios.post('/store/item/new', { name, category: categoryId, vendorKey })
  }

  // 아이템 상세정보
  getStoreItem(itemId) {
    return this._axios.get('/store/item/info', { params: { item: itemId } })
  }

  /**
   * @typedef {Object} updateStoreItem~data
   * @property {String} [title]
   * @property {Number} [stock]
   * @property {Number} [price]
   * @property {Number} [point]
   * @property {String} [category]
   * @property {boolean} [onSale]
   * @property {boolean} [banned]
   * @property {boolean} [optionRequired]
   */
  /**
   * @param {string}itemId
   * @param {updateStoreItem~data}[data]
   */
  updateStoreItem(itemId, data) {
    return this._axios.put('/store/item/info', data, { params: { item: itemId } })
  }

  removeStoreItem(itemId) {
    return this._axios.delete('/store/item/info', { params: { item: itemId } })
  }

  uploadStoreItemImage(itemId, main, description) {
    const form = new FormData();
    if (main) {
      form.append('main', main)
    }
    _.forEach(description, (d) => {
      form.append('description', d)
    })
    return this._axios.post('/store/item/image', form, {
      params: { item: itemId },
      headers: { 'content-type': 'multipart/form-data' }
    })
  }

  getCategoryList() {
    return this._axios.get('/store/category/list')
  }

  getStoreItemListForAdmin(vendor, page, pageMax, sort, filter) {
    const options = ApiClient.createPaginationOption(page, pageMax, sort, filter)
    return this._axios.post('/store/item/list', { vendor, ...options })
  }

  isStoreManager() {
    return this._axios.get('/store/is/manager')
  }

  getDailyTransactionReport(date) {
    return this._axios.post('/point/report/daily/transaction', null, { params: { date } })
  }

  //메인 이미지 삭제
  deleteMainImage(id) {
    return this._axios.delete('/store/item/image/main', { params: { item: id } })
  }

  // description 이미지 삭제
  deleteDescriptionImage(id, imageId) {
    return this._axios.delete('/store/item/image/description', { params: { item: id, image: imageId } })
  }

  //아이템 옵션 추가
  addItemOption(id, data) {
    return this._axios.post('/store/item/option/new', data, { params: { item: id } })
  }

  //아이템 옵션 업데이트
  updateItemOption(id, data) {
    return this._axios.put('/store/item/option/info', data, { params: { option: id } })
  }

  //아이템 옵션 삭제
  deleteItemOption(id) {
    return this._axios.delete('/store/item/option/info', { params: { option: id } })
  }

  //gym 등록
  createGym(data) {
    return this._axios.post('/gym', data)
  }

  //gym 리스트
  getGymList(page, pageMax, sort, filter) {
    const options = ApiClient.createPaginationOption(...arguments)
    return this._axios.post('/gym/list', options)
  }

  //gym 디테일
  getGymDetail(id) {
    return this._axios.get(`/gym/${id}`)
  }

  //gym 삭제
  deleteGym(id) {
    return this._axios.delete(`/gym/${id}`)
  }

  //gym 정보 업데이트
  updateGymDetail(id, data) {
    return this._axios.put(`/gym/${id}`, data)
  }

  //gym 사용량 정보(오늘일 기준으로 일간, 주간, 월간 선택)

  //gym 사용량 정보 (기간 설정)
  getGymStatRange(id, start = undefined, end = undefined) {
    return this._axios.get(`/gym/${id}/stat/range`, { params: { start, end } })
  }

  //dashboard auth group list 가져오기
  getDashboardAuthGroup() {
    return this._axios.get('/dashboard/auth/group')
  }

  //dashboard auth group 추가
  putDashboardAuthGroup(key) {
    return this._axios.put('/dashboard/auth/group', null, { params: { group: key } })
  }

  //dashboard auth group 삭제
  deleteDashboardAuthGroup(key) {
    return this._axios.delete('/dashboard/auth/group', { params: { group: key } })
  }

  //대시보드 일간 운동 결과 조회
  getDailyWorkout(date) {
    return this._axios.get('/dashboard/workout/daily', { params: { date } })
  }

  getTotalWorkout() {
    return this._axios.get('/dashboard/workout/total')
  }

  //device 추가
  addDevice(data) {
    return this._axios.post('/device', data)
  }

  //device 리스트
  getDeviceList(page, pageMax, sort, filter) {
    const options = ApiClient.createPaginationOption(...arguments)
    return this._axios.post('/device/list', options)
  }

  //device 정보
  getDeviceInfo(deviceId) {
    return this._axios.get(`/device/${deviceId}`)
  }

  //device 정보 수정
  updateDeviceInfo(deviceId, data) {
    return this._axios.put(`/device/${deviceId}`, data)
  }

  //device 삭제
  deleteDevice(deviceId) {
    return this._axios.delete(`/device/${deviceId}`)
  }

  getOrderManagerAuthGroup() {
    return this._axios.get('/order/auth/manager')
  }

  insertOrderManagerAuthGroup(group) {
    return this._axios.put('/order/auth/manager', null, { params: { group } })
  }

  removeFromOrderManagementAuthGroup(group) {
    return this._axios.delete('/order/auth/manager', { params: { group } })
  }

  //주문배송 리스트
  getOrderList(page, pageMax, sort, filter) {
    const options = ApiClient.createPaginationOption(...arguments)
    return this._axios.post('/order/list/admin', options)
  }

  getOrderDeliveryItem(orderId) {
    return this._axios.get(`/order/${orderId}`)
  }

  getDeliveryCarriers() {
    this._axiosDelivery = axios.create({
      baseURL: 'https://apis.tracker.delivery'
    })
    return this._axiosDelivery.get(`/carriers`)
  }

  updateOrderDeliveryItem(orderId, data) {
    return this._axios.put(`/order/${orderId}/status/update`, data)
  }

  orderPaymentCancel(orderId) {
    return this._axios.put(`/order/${orderId}/cancel`)
  }

  // update password
  updatePassword(currentPass, newPass) {
    return this._axios.put('/user/password', { currentPass, newPass })
  }

  getUserWorkoutOfGym(userId, start, end) {
    return this._axios.get(`/user/${userId}/workout/gym`, { params: { start, end } })
  }

  getUserWorkoutList(userId, { page, pageMax, filter, sort } = {}) {
    return this._axios.post(`/user/${userId}/workout`, { page, pageMax, filter, sort })
  }

  getWorkoutDetail(workoutId) {
    return this._axios.get(`/workout/${workoutId}`)
  }

  // reset password
  resetPassword(email) {
    return this._axios.post('/user/password/reset', { email })
  }

  // get challenge event
  getChallengeRankList() {
    return this._axios.get('/challenge/rank/event')
  }

  // get challenge rank list with pagination
  getRankList({ page, pageMax, category, type, date } = {}) {
    return this._axios.post('/challenge/device/rank/list', { page, pageMax, category, type, date })
  }

  // 특정 Gym의 유저 리스트
  getUsersOfGym(gymId, { page, pageMax, filter, sort } = {}) {
    return this._axios.post(`/gym/${gymId}/user/list`, { page, pageMax, filter, sort })
  }

  getDevicesOfGym(gymId, { page, pageMax, filter, sort } = {}) {
    return this._axios.post(`/gym/${gymId}/tablet/list`, { page, pageMax, filter, sort })
  }

  getGymDetailStat(gymId, type, start, end) {
    return this._axios.get(`/stat/gym/${gymId}`, { params: { type: Number(type), start, end } })
  }

  getAllUsersOfGym(gymId, start, end) {
    return this._axios.get(`/gym/${gymId}/users`, { params: { start, end } })
  }

  // gym별 stat
  getStatsByGym(type, start, end, gymId = undefined) {
    return this._axios.get('/stat/gym', { params: { type: Number(type), start, end, gymId } })
  }

  // 지역별 stat
  getStatsByRegion(type, start, end, bjCode = undefined) {
    return this._axios.get('/stat/region', { params: { type: Number(type), start, end, bjCode } })
  }

  //시도 get
  getSiDoList() {
    return this._axios.get('/region/siDo')
  }

  getSiGunGuList(siDoCode) {
    return this._axios.get('/region/siGunGu', { params: { siDoCode } })
  }

  getDongList(siGunGuCode) {
    return this._axios.get('/region/dong', { params: { siGunGuCode } })
  }

  // getGymDetailStat(gymId, type, start, end) {
  //   return this._axios.get(`/stat/gym/${gymId}`, { params: { type: Number(type), start, end } })
  // }

  getDownloadFile(gymId, type, start, end) {
    return this._axios.get(`/download/csv/gym/${gymId}`, { params: { type: Number(type), start, end }, headers: { responseType: 'blob'} })
  }

  forceChangePassword(userId, newPassword) {
    return this._axios.put('/user/change/password', { userId, newPassword })
  }

  //tablet 리스트
  getTabletList(page, pageMax, sort, filter){
    const options = ApiClient.createPaginationOption(...arguments)
    return this._axios.post('/device/tablet/list', options)
  }

  getTablet(tabletId){
    return this._axios.get(`/device/tablet/${tabletId}`)
  }

  deleteTablet(tabletId) {
    return this._axios.delete(`/device/tablet/${tabletId}`)
  }

  // bike workout config 수정
  putBikeWorkoutConfig(numerator) {
    return this._axios.put('/config/bike/rate', { numerator })
  }

  getBikeWorkoutConfig() {
    return this._axios.get('/config/bike/rate')
  }

  // app category
  createAppCategory(name) {
    return this._axios.post('/category', { name })
  }

  getAppCategoryInfo(categoryId) {
    return this._axios.get(`/category/${categoryId}`)
  }

  getAppCategoryList(page, pageMax, sort, filter){
    const options = ApiClient.createPaginationOption(...arguments)
    return this._axios.post('/category/list', options)
  }

  deleteAppCategory(categoryId) {
    return this._axios.delete(`/category/${categoryId}`)
  }

  updateAppCategory(categoryId, name) {
    return this._axios.put(`/category/${categoryId}`, { name })
  }

  getAllAppCategories() {
    return this._axios.get('/category/list')
  }

  // application
  createApplication(data) {
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v, i) => {
          formData.append(key + '[]', v)
        })
      } else {
        formData.append(key, value)
      }
    })
    return this._axios.post('/application', formData,
      { headers: { 'content-type': 'multipart/form-data' } }
    )
  }

  getApplicationList(page, pageMax, sort, filter){
    const options = ApiClient.createPaginationOption(...arguments)
    return this._axios.post('/application/list', options)
  }

  getApplicationInfo(applicationId) {
    return this._axios.get(`/application/${applicationId}`)
  }

  updateApplicationController(applicationId, controller) {
    return this._axios.put(`/application/${applicationId}/controller`, { controller })
  }

  deleteApplication(applicationId) {
    return this._axios.delete(`/application/${applicationId}`)
  }

  updateApplicationDetailImages(applicationId, detailImages) {
    const form = new FormData()
    if (_.isArray(detailImages)) {
      detailImages.forEach((i) => {
        if (i instanceof File) { // i.name
          form.append('detailImages', i)
        }
      })

    } else {
      form.append('detailImages', detailImages)
    }
    return this._axios.put(`/application/${applicationId}/image`, form,
      { headers: { 'content-type': 'multipart/form-data' } }
    )
  }

  updateApplication(applicationId, data) {
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v, i) => {
          formData.append(key + '[]', v)
        })
      } else {
        formData.append(key, value)
      }
    })
    return this._axios.put(`/application/${applicationId}`, formData,
      { headers: { 'content-type': 'multipart/form-data' } }
    )
  }

  deleteApplicationDetailImage(applicationId, imageId) {
    return this._axios.delete(`/application/${applicationId}/image/${imageId}`)
  }

  // common key controller
  updateCommonKeyController(data) {
    return this._axios.put('/commonKey/controllers', { controller: data })
  }
  getCommonKeyController() {
    return this._axios.get('/commonKey/controllers')
  }

  // cs
  getCsDetail(csId) {
    return this._axios.get(`/question/${csId}`)
  }

  replyCs(csId, data) {
    return this._axios.post(`/question/${csId}/answer`, data)
  }

  getCsList(range, page, pageMax, filter, sort) {
    return this._axios.post(`/question/list/${range}`, { page, pageMax, filter, sort })
  }

  // deleteCs(csId) {
  //
  // }

  // 이용약관
  createTerms(data) {
    return this._axios.post('/terms', data)
  }

  updateTerms(termsId, data) {
    return this._axios.put(`/terms/${termsId}`, data)
  }

  deleteTerms(termsId) {
    return this._axios.delete(`/terms/${termsId}`)
  }

  getTerms(termsId) {
    return this._axios.get(`/terms/${termsId}`)
  }

  getTermsList({ page, pageMax, filter, sort } = {}) {
    return this._axios.post('/terms/list', { page, pageMax, filter, sort })
  }

  givePointToUser(userId, point, description) {
    return this._axios.post('/point/give', { userId, amount: point, shortDescription: description })
  }

  // gym 매장 이미지
  uploadGymImage(gymId, image) {
    const form = new FormData()
    if (_.isArray(image)) {
      image.forEach((i) => {
        if (i instanceof File) { // i.name
          form.append('image', i)
        }
      })

    } else {
      form.append('files', image)
    }
    return this._axios.put(`/gym/${gymId}/image`, form,
      { headers: { 'content-type': 'multipart/form-data' } }
    )
  }

  deleteGymImage(gymId, imgId) {
    return this._axios.delete(`/gym/${gymId}/image/${imgId}`)
  }
  // 탈퇴사유
  getUnregisterReasonDetail(reasonId) {
    return this._axios.get(`/unregisterReason/${reasonId}`)
  }

  deleteUnregisterReasonDetail(reasonId) {
    return this._axios.delete(`/unregisterReason/${reasonId}`)
  }

  getUnregisterReasonList({ page, pageMax, filter, sort} = {}) {
    return this._axios.post('/unregisterReason/list', { page, pageMax, filter, sort })
  }

  createUnregisterReason(data) {
    return this._axios.post('/unregisterReason', data)
  }

  updateUnregisterReason(reasonId, data) {
    return this._axios.put(`/unregisterReason/${reasonId}`, data)
  }

  // for qa
  deleteUserForQa(userId) {
    return this._axios.delete(`/qa/user/${userId}`)
  }

  insertWorkoutDataForQa(userId, data) {
    return this._axios.post(`/qa/workout/user/${userId}`, data)
  }

  deleteWorkoutDataForQa(workoutId) {
    return this._axios.delete(`/qa/workout/${workoutId}`)
  }

  deleteWorkoutAndPointDataOfUserForQa(userId) {
    return this._axios.delete(`/qa/workout/user/${userId}`)
  }

  getUserInfoByKakaoId(kakaoId) {
    return this._axios.get('/user/info/kakaoId', { params: { kakaoId } })
  }

  // 기존 카카오 계정 유저, 새 카카오 계정 유저 정보 통합
  mergeUserInfo(data) {
    return this._axios.put('/user/merge/kakaoId', data)
  }

}

/**
 * @returns {ApiClient}
 */
export default function () {
  if (!staticClient) {
    staticClient = new ApiClient()
  }
  return staticClient
}
