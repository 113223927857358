import React from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/ko';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { Route, Switch, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack'
import { createAuthRoute } from './components/AuthRouter';
import { SiteUrls } from './routes/siteUrl';
import Notifier from './views/Notification/Notifier';
import { isLogin } from './utils/loginChecker'
import Loadable from './views/Common/Loadable'

import './assets/scss/common.scss'

const AuthRoute = createAuthRoute(isLogin, SiteUrls.Pages.Login);
const Main = Loadable({
  loader: () => import('./views/Main/Main')
})
const Pages = Loadable({
  loader: () => import('./views/Pages/Pages')
})

const App = ({ history }) => (
  <SnackbarProvider
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
  >
    <div>
      <Notifier />
      <ConnectedRouter history={history}>
        <Switch>
          <Route path={SiteUrls.Pages.Main} name="Pages" component={Pages} />
          <AuthRoute path={SiteUrls.Dashboard.Main} name="Main" component={Main} />
          <Redirect from="/" to={SiteUrls.Dashboard.Dashboard} name="Dashboard" />
        </Switch>
      </ConnectedRouter>
    </div>
  </SnackbarProvider>
);

App.propTypes = {
  history: PropTypes.object
};

export default App;
