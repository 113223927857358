import { call, takeLatest, takeEvery, select } from 'redux-saga/effects'
import { selectLocation } from '../selectors/routerSelector'
import { selectPointList } from '../selectors/point'
import API from './apiClient'
import * as A from '../actions/point'
import { simpleAPI, simpleInfoAPI } from './common'
import { SiteUrls } from '../routes/siteUrl'

function* getTradingRate() {
  yield call(
    simpleAPI,
    () => API().getExchangeRate(),
    A.getTradingRate,
  )
}

function* setTradingRate({ payload: rate }) {
  yield call(
    simpleAPI,
    () => API().setExchangeRate(rate),
    A.setTradingRate,
  )
}

function* getTradingGroup() {
  yield call(
    simpleAPI,
    () => API().getPointAuthGroup(),
    A.getTradingGroup
  )
}

function* insertIntoTradingGroup({ payload: { groupType, key } }) {
  yield call(
    simpleAPI,
    () => API().insertPointAuthGroup(groupType, key),
    A.putTradingGroup,
  )
}

function* removeFromTradingGroup({ payload: { groupType, key } }) {
  yield call(
    simpleAPI,
    () => API().removePointAuthGroup(groupType, key),
    A.removeFromTradingGroup,
  )
}

function* getTradingRequestList({ payload: { page, pageSize, sort, filter } }) {
  yield call(
    simpleAPI,
    () => API().getExchangeRequestList(page, pageSize, sort, filter),
    A.getTradingRequestList,
  )
}

function* getTradingRequestDetail({ payload: { id } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().getExchangeRequestInfo(id),
    A.getTradingRequestDetail,
  )
}

function* lockRequest({ payload: { id } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().lockExchangeRequest(id),
    A.getTradingRequestDetail,
  )
  const current = yield select(selectLocation)
  if (current.get('pathname') === SiteUrls.Dashboard.Point.List) {
    const params = yield select(selectPointList.param)
    yield call(getTradingRequestList, { payload: params })
  }
}

function* transactRequest({ payload: { id } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().transactRequest(id),
    A.getTradingRequestDetail,
  )
}

function* dailyTransactionReport({ payload: date }) {
  yield call(
    simpleAPI,
    () => API().getDailyTransactionReport(date),
    A.dailyTransactionReport,
  )
}

export default function* rootSaga() {
  yield takeLatest(A.getTradingRate, getTradingRate)
  yield takeLatest(A.setTradingRate, setTradingRate)
  yield takeLatest(A.getTradingGroup, getTradingGroup)
  yield takeLatest(A.getTradingRequestList, getTradingRequestList)
  yield takeLatest(A.dailyTransactionReport, dailyTransactionReport)
  yield takeEvery(A.putTradingGroup, insertIntoTradingGroup)
  yield takeEvery(A.removeFromTradingGroup, removeFromTradingGroup)
  yield takeEvery(A.getTradingRequestDetail, getTradingRequestDetail)
  yield takeEvery(A.lockRequest, lockRequest)
  yield takeEvery(A.transactRequest, transactRequest)
}
