import { fromJS } from 'immutable';
import { createReducer } from 'redux-act';
import * as A from '../actions/authAction';

const initialState = fromJS({
  token: null,
  user: null,
  loginProgress: false,
});

const loginProgressHandler = (state) => state.set('loginProgress', true)
const loginCompleteHandler = (state, payload) => state
  .set('loginProgress', false)
  .set('user', fromJS(payload.user))
  .set('token', payload.token)
const loginErrorHandler = (state) => state
  .set('loginProgress', false)
  .set('user', null)
  .set('token', null)

export default createReducer({
  [A.LOGIN_START]: loginProgressHandler,
  [A.LOGIN_COMPLETE]: loginCompleteHandler,
  [A.LOGIN_ERROR]: loginErrorHandler,
  [A.LOG_OUT]: (state) => state
    .set('user', null)
    .set('token', null)
}, initialState);
