import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import {
  createApiHandler,
  apiInitialState, paginationInitialState, infoApiInitialState, createPaginationHandler, createInfoApiHandler,
} from './common'
import * as A from '../actions/user'

export const NAME_USER = {
  ROOT: 'user',
  SEARCH: 'search',
  LIST: 'list',
  INFO: 'info',
}

const initialState = fromJS({
  [NAME_USER.SEARCH]: apiInitialState(),
  [NAME_USER.LIST]: paginationInitialState(),
  [NAME_USER.INFO]: infoApiInitialState(),
})

const handlers = Object.assign({},
  createApiHandler(A.searchUser, NAME_USER.SEARCH),
  createPaginationHandler(A.getUserList, NAME_USER.LIST),
  createInfoApiHandler(A.getUserInfo, NAME_USER.INFO),
  createInfoApiHandler(A.updateUserInfo, NAME_USER.INFO),
)

export default createReducer(handlers, initialState)
