import _ from 'lodash'
import { createSelector } from 'reselect'
import { createApiSelector, createInfoSelectorMaker, createPaginationSelectors } from './common'
import { NAME_STORE as NAME } from '../reducers/store'

export const selectStore = state => state[NAME.ROOT]
export const selectStoreAuthGroup = createApiSelector(createSelector(selectStore, s => s.get(NAME.AUTH_GROUPS)))
export const selectNewItem = createApiSelector(createSelector(selectStore, s => s.get(NAME.NEW_ITEM)))
export const selectItemInfo = createInfoSelectorMaker(createSelector(selectStore, s => s.get(NAME.ITEM_INFO)))
export const selectCategoryList = createApiSelector(createSelector(selectStore, s => s.get(NAME.CATEGORY_LIST)))
export const selectCategoryMap = createSelector(selectCategoryList.toJS, (list) => {
  if (!list) {
    return { dict: {}, root: [] }
  }

  function insertToDict(item, parent, dict) {
    _.forEach(item.child, c => insertToDict(c, item.id, dict))
    dict[item.id] = { ...item, parent }
  }

  const dictionary = {}
  _.forEach(list, i => insertToDict(i, null, dictionary))
  return { dict: dictionary, root: list }
})
export const selectMyVendorList = createApiSelector(createSelector(selectStore, s => s.get(NAME.VENDOR_LIST)))
export const selectItemList = createPaginationSelectors(createSelector(selectStore, s => s.get(NAME.ITEM_LIST)))
export const selectIsManager = createApiSelector(createSelector(selectStore, s => s.get(NAME.IS_MANAGER)))
export const selectUpdatesList = createApiSelector(createSelector(selectStore, s => s.get(NAME.UPDATES_IN_LIST)))
export const selectListInUpdates = createSelector([selectItemList.isLoading, selectUpdatesList.isLoading], (list, updates) => list || updates)

//이미지 업로드
export const selectUploadItemImage = createInfoSelectorMaker(createSelector(selectStore, s => s.get(NAME.ITEM_INFO)))

//item option
export const selectAddOption = createInfoSelectorMaker(createSelector(selectStore, s => s.get(NAME.ITEM_INFO)))
export const selectUpdateOption = createInfoSelectorMaker(createSelector(selectStore, s => s.get(NAME.ITEM_INFO)))