import moment from 'moment'
import _ from 'lodash'

///onSale select tag options
export const onSaleType = [
  { value: 'true', item: '판매중' },
  { value: 'false', item: '판매중지' }
]

export const bannedType = [
  { value: 'true', item: '차단' },
  { value: 'false', item: '비차단' }
]

export const optionRequiredType = [
  { value: 'true', item: '활성화'},
  { value: 'false', item: '비활성화'}
]

export const isOnsiteItemType = [
  { value: 'true', item: '활성화'},
  { value: 'false', item: '비활성화'}
]

export const itemDeliveryType = [
  { value: 1, item: '택배 배송'},
  { value: 2, item: '온라인 선물'},
  { value: 3, item: '현장 쿠폰'},
]

export const formData = (doc) => {
  if (!doc) return {}

  function makeDateString(date) {
    if (date) {
      return moment(date).format('YYYY-MM-DD')
    }
    return ''
  }
  doc.createdAt = makeDateString(doc.createdAt)
  doc.price = doc.price.toLocaleString()
  doc.point = doc.point.toLocaleString()
  return doc
}

