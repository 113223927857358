import { makeErrDef as E } from './common'

/**
 * @mixin
 */
const versionError = {
  VERSION_NOT_FOUND: E(404, 87000, 'Version is not found'),
  VERSION_NOT_LATEST: E(412, 87001, 'Version is not latest'),
}

export default Object.freeze(versionError)
