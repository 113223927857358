import { call, put, throttle, takeLatest, takeEvery, select } from 'redux-saga/effects'
import API from './apiClient'
import { ENQUEUE_NOTIFICATION, NOTI_TYPE } from '../actions/notificationAction'
import { selectLocation } from '../selectors/routerSelector'
import * as A from '../actions/authGroup'
import { simpleAPI, simpleInfoAPI } from './common'
import { SiteUrls } from '../routes/siteUrl'

function* searchAuth({ payload: { format, count } }) {
  yield call(
    simpleAPI,
    () => API().searchAuth(format, count),
    A.searchAuth,
  )
}

function* getAuthTree() {
  yield call(
    simpleAPI,
    () => API().getAuthTree(),
    A.getAuthTree,
  )
}

function* createNewGroup({ payload: { key, parent, type } }) {
  const result = yield call(
    simpleAPI,
    () => API().createAuth(key, parent, type),
    A.createNewGroup,
  )
  if (!result) {
    return
  }
  const current = yield select(selectLocation)
  if (current.get('pathname') === SiteUrls.Dashboard.Auth._) {
    yield call(getAuthTree)
  }
}

function* deleteAuth({ payload: { id } }) {
  const result = yield call(
    simpleInfoAPI,
    id,
    () => API().deleteAuth(id),
    A.deleteAuth,
  )
  if (!result) {
    return
  }
  yield put(A.deleteAuth.invalidate(id))
  yield put(ENQUEUE_NOTIFICATION(`권한 [${id}] 가 삭제되었습니다.`, NOTI_TYPE.INFO))
  const current = yield select(selectLocation)
  if (current.get('pathname') === SiteUrls.Dashboard.Auth._) {
    yield call(getAuthTree)
  }
}

function* insertUser({ payload: { id, user, level } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().insertUserToAuth(id, user, level),
    A.insertUser,
  )
}

function* removeUser({ payload: { id, user } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().removeUserFromAuth(id, user),
    A.removeUser
  )
}

function* updateUser({ payload: { id, user, level } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().updateUserLevel(id, user, level),
    A.updateUser
  )
}

function* getInfo({ payload: { id } }) {
  yield call(
    simpleInfoAPI,
    id,
    () => API().getAuthInfo(id),
    A.getInfo
  )
}

export default function* rootSaga() {
  yield throttle(1000, A.searchAuth, searchAuth)
  yield takeEvery(A.getInfo, getInfo)
  yield takeEvery(A.createNewGroup, createNewGroup)
  yield takeLatest(A.getAuthTree, getAuthTree)
  yield takeEvery(A.deleteAuth, deleteAuth)
  yield takeEvery(A.insertUser, insertUser)
  yield takeEvery(A.removeUser, removeUser)
  yield takeEvery(A.updateUser, updateUser)
}
