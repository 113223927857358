import { createReducer } from 'redux-act'
import { fromJS } from 'immutable'
import {
  createApiHandler,
  createInfoApiHandler,
  apiInitialState,
  infoApiInitialState,
  paginationInitialState, createPaginationHandler,
} from './common'
import * as A from '../actions/point'

export const NAME_POINT = {
  ROOT: 'point',
  DETAIL: 'detail',
  LIST: 'list',
  GROUP: 'group',
  RATE: 'rate',
  DAILY_REPORT: 'daily',
}

const initialState = fromJS({
  [NAME_POINT.DETAIL]: infoApiInitialState(),
  [NAME_POINT.LIST]: paginationInitialState(),
  [NAME_POINT.GROUP]: apiInitialState(),
  [NAME_POINT.RATE]: apiInitialState(),
  [NAME_POINT.DETAIL]: infoApiInitialState(),
  [NAME_POINT.DAILY_REPORT]: apiInitialState(),
})

const handlers = Object.assign({},
  createApiHandler(A.getTradingGroup, NAME_POINT.GROUP),
  createApiHandler(A.putTradingGroup, NAME_POINT.GROUP),
  createApiHandler(A.removeFromTradingGroup, NAME_POINT.GROUP),
  createPaginationHandler(A.getTradingRequestList, NAME_POINT.LIST),
  createInfoApiHandler(A.getTradingRequestDetail, NAME_POINT.DETAIL),
  createApiHandler(A.getTradingRate, NAME_POINT.RATE),
  createApiHandler(A.setTradingRate, NAME_POINT.RATE),
  createInfoApiHandler(A.transactRequest, NAME_POINT.DETAIL),
  createInfoApiHandler(A.lockRequest, NAME_POINT.DETAIL),
  createApiHandler(A.dailyTransactionReport, NAME_POINT.DAILY_REPORT),
)

export default createReducer(handlers, initialState)
