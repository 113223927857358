import { createSelector } from 'reselect';
import { isFunction } from 'lodash';



export function createPaginationSelectors(baseSelector) {
  const isLoading = createSelector(baseSelector, list => list.get('isLoading'));
  const pages = createSelector(baseSelector, list => list.get('pages'));
  const data = createSelector(baseSelector, list => list.get('data'));
  const toJS = createSelector(data, data => data.toJS());
  const param = createSelector(baseSelector, list => ({
    lastSort: list.get('lastSort').toJS(),
    lastFilter: list.get('lastFilter').toJS(),
    page: list.get('page'),
    pageSize: list.get('pageSize'),
  }))
  const lastData = createSelector(baseSelector, list => list.get('lastData'));
  const make = converter => {
    // Converting data is a most costly work. So make another wrapping selector for it.
    const selectConverted = converter ? createSelector(list => list.get('data'), (data) => {
        if (data) {
          return converter(data.toJS())
        }
        return []
      })
      : (list) => {
        const data = list.get('data');
        if (data) {
          return data.toJS()
        }
        return []
      }
    return createSelector(baseSelector, list => ({
      loading: list.get('isLoading'),
      pages: list.get('pages'),
      error: list.get('error'),
      page: list.get('page'),
      data: selectConverted(list),
    }))
  }
  return { isLoading, pages, data, toJS, make, param, lastData }
}

export function createApiSelector(baseSelector) {
  const isLoading = createSelector(baseSelector, api => api.get('isInProgressing'));
  const error = createSelector(baseSelector, api => api.get('error'));
  const data = createSelector(baseSelector, api => api.get('data'));
  const toJS = createSelector(data, data => data && isFunction(data.toJS) ? data.toJS() : data)
  const all = createSelector(baseSelector, api => {
    const data = api.get('data');
    return {
      isLoading: api.get('isInProgressing'),
      error: api.get('error'),
      timestamp: api.get('timestamp'),
      data: data && isFunction(data.toJS) ? data.toJS() : data
    }
  })
  const stat = createSelector(baseSelector, api => ({
    isLoading: api.get('isInProgressing'),
    error: api.get('error'),
    timestamp: api.get('timestamp'),
  }))
  return { isLoading, error, data, toJS, all, stat }
}

export function createInfoSelector(baseSelector, idExtractor) {
  const idSelector = createSelector([baseSelector, idExtractor], (base, id) => base.get(id))
  const isLoading = createSelector(idSelector, data => data && data.get('isInProgressing'))
  const error = createSelector(idSelector, data => data && data.get('error'))
  const data = createSelector(idSelector, data => (data && data.get('data')) || null)
  const all = createSelector(idSelector, data => {
    if (!data) {
      return {
        isLoading: false,
        error: false,
        timestamp: 0,
        data: null
      }
    }
    const infoData = data.get('data')
    return {
      isLoading: data.get('isInProgressing'),
      error: data.get('error'),
      timestamp: data.get('timestamp'),
      data: (infoData && isFunction(infoData.toJS)) ? infoData.toJS() : infoData,
    }
  })
  const stat = createSelector(idSelector, data => {
    if (!data) {
      return {
        isLoading: false,
        error: false,
        timestamp: 0,
      }
    }
    return {
      isLoading: data.get('isInProgressing'),
      error: data.get('error'),
      timestamp: data.get('timestamp'),
    }
  })
  return { isLoading, error, data, all, stat }
}

export function createInfoSelectorMaker(baseSelector) {
  return (id, formatter = (d) => d) => {
    const idSelector = createSelector(baseSelector, base => base.get(id))
    const isLoading = createSelector(idSelector, data => data && data.get('isInProgressing'))
    const error = createSelector(idSelector, data => data && data.get('error'))
    const data = createSelector(idSelector, data => (data && data.get('data')) || null)
    const toJS = createSelector(data, data => formatter(data && isFunction(data.toJS) ? data.toJS() : data))
    const all = createSelector(idSelector, data => {
      if (!data) {
        return {
          isLoading: false,
          error: false,
          timestamp: 0,
          data: null
        }
      }
      const infoData = data.get('data')
      return {
        isLoading: data.get('isInProgressing'),
        error: data.get('error'),
        timestamp: data.get('timestamp'),
        data: formatter((infoData && isFunction(infoData.toJS)) ? infoData.toJS() : infoData),
      }
    })
    const stat = createSelector(idSelector, data => {
      if (!data) {
        return {
          isLoading: false,
          error: false,
          timestamp: 0,
        }
      }
      return {
        isLoading: data.get('isInProgressing'),
        error: data.get('error'),
        timestamp: data.get('timestamp'),
      }
    })
    return { isLoading, error, data, toJS, all, stat }
  }
}
