import { createApiAction, createInfoApiAction, createPaginationAction } from './common'

export const getStoreManagerAuthGroup = createApiAction('getStoreManagerAuthGroup')
export const insertStoreManagerAuthGroup = createApiAction('insertStoreManagerAuthGroup')
export const removeFromStoreManagementAuthGroup = createApiAction('removeFromStoreManagementAuthGroup')
export const createNewStoreItem = createApiAction('createNewStoreItem',
  (name, categoryId, vendorKey) => ({ name, categoryId, vendorKey }))
export const getStoreItem = createInfoApiAction('getStoreItem', (id) => ({ id }))
export const updateStoreItem = createInfoApiAction('updateStoreItem', (id, data) => ({ id, data }))
export const uploadStoreItemImage = createInfoApiAction('uploadStoreItemImage',
  (id, main, description) => ({ id, main, description }))
export const getCategoryList = createApiAction('getCategoryList')
export const getMyVendorList = createApiAction('getMyVendorList')
export const getStoreItemList = createPaginationAction('getStoreItemList')
export const isManager = createApiAction('isManager')
export const deleteItemInList = createApiAction('deleteItemFromList', (id) => ({ id }))
export const banItemInList = createApiAction('banItemFromList', (id, banned) => ({ id, banned }))
export const setSaleInList = createApiAction('setSaleFromList', (id, onSale) => ({ id, onSale }))

export const deleteMainImage = createInfoApiAction('deleteMainImage', (id) => ({id}))
export const deleteDescriptionImage = createInfoApiAction('deleteDescriptionImage' , (id, imageId) => ({id, imageId}))

//아이템 옵션 관련
export const addItemOption = createInfoApiAction('addItemOptions', (id, optionId, data) => ({id, optionId, data}))
export const updateItemOption = createInfoApiAction('updateItemOptions', (id, optionId, data) =>({id, optionId, data}))
export const deleteItemOption = createApiAction('deleteItemOptions', (id, optionId) => ({id, optionId}))