import { createSelector } from 'reselect'
import { createApiSelector, createPaginationSelectors } from './common'
import { NAME_VERSION } from '../reducers/version'

export const selectVersion = state => state[NAME_VERSION.ROOT]
const selectFwList = createSelector(selectVersion, version => version.get(NAME_VERSION.FW_LIST))
const selectClient = createSelector(selectVersion, version => version.get(NAME_VERSION.CLIENT))
const selectFw = createSelector(selectVersion, version => version.get(NAME_VERSION.FW))
export const selectFwPaginationApi = createPaginationSelectors(selectFwList)
export const selectFwApi = createApiSelector(selectFw)
export const selectClientApi = createApiSelector(selectClient)
