import { createPaginationAction, createApiAction, createInfoApiAction } from './common'

export const createAdvertisement = createApiAction('create advertisement')
export const uploadMedia = createInfoApiAction('upload media', (id, media) => ({ id, media }))
export const deleteMedia = createInfoApiAction('delete media')
export const getAdvertisement = createInfoApiAction('get advertisement')
export const updateAdvertisement = createInfoApiAction('update advertisement', (id, data) => ({ id, data }))
export const removeAdvertisement = createInfoApiAction('delete advertisement')
export const createFilter = createInfoApiAction('create filter', (id, data) => ({ id, data }))
export const getFilters = createInfoApiAction('get filters')
export const removeFilter = createInfoApiAction('remove filter', (id, filterKey) => ({ id, filterKey }))
export const updateFilter = createInfoApiAction('update filter', (id, filterKey, data) => ({ id, filterKey, data }))
export const getAdvertisementList = createPaginationAction('get advertisement list')
