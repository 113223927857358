import { all, fork } from 'redux-saga/effects';
import authSaga from './authSaga';
import noticeSaga from './noticeSaga';
import versionSaga from './version';
import adSaga from './advertisement';
import authGroupSaga from './authGroup'
import pointSaga from './point';
import userSaga from './user'
import workoutSaga from './wokrout'
import storeSaga from './store'
import deviceSaga from './device'
import gymSaga from './gym'
import dashboardSaga from './dashboard'
import orderSaga from './order'

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(noticeSaga),
    fork(versionSaga),
    fork(adSaga),
    fork(authGroupSaga),
    fork(pointSaga),
    fork(userSaga),
    fork(workoutSaga),
    fork(storeSaga),
    fork(deviceSaga),
    fork(gymSaga),
    fork(dashboardSaga),
    fork(orderSaga),
  ]);
}
