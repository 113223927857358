import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';

const style = {
  grid: {
    padding: '0 15px !important'
  }
};
const useStyles = makeStyles(style)

function GridItem({ ...props }) {
  const classes = useStyles()
  const { children, className, ...rest } = props;
  return (
    <Grid item {...rest} className={classes.grid + ' ' + className}>
      {children}
    </Grid>
  );
}

export default GridItem
